import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { creditUsagePopupState } from "Components/common/CreditsUsagePopup";

const CreditUsageTimer = observer(({ initialseconds = 0 }) => {
  const [timeLeft, setTimeLeft] = useState(initialseconds);
  const [time, setTime] = useState({});

  const formatTimer = (time, format = "") => {
    if (format === "hours") {
      if (time < 1) {
        return;
      }
    }
    if (time < 10) {
      return `0${time}`;
    }
    return time;
  };

  const getUpdatedTimer = () => {
    const hours = formatTimer(time?.hours, "hours");
    const minutes = formatTimer(time?.minutes) || "00";
    const seconds = formatTimer(time?.seconds) || "00";
    return `${hours}:${minutes}:${seconds}`;
  };

  useMemo(() => {
    const hours = Math.floor(timeLeft / 3600000);
    const minutes = Math.floor((timeLeft % 3600000) / 60000);
    const seconds = Math.floor((timeLeft % 60000) / 1000);

    setTime({ ...{ hours, minutes, seconds } });
  }, [timeLeft]);

  useEffect(() => {
    let intervalId = null;
    if (
      creditUsagePopupState.showPopup &&
      !creditUsagePopupState.upgradeToRemoveDailyLimit
    ) {
      intervalId = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1000);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, []);

  return <div className="finalTimerWrapper">{getUpdatedTimer()}</div>;
});

export default CreditUsageTimer;
