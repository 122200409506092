import React from "react";
import { ReactComponent as PhoneCallIcon } from "./Icons/phoneCall.svg";
import { ReactComponent as EmailIcon } from "./Icons/email.svg";
import { ReactComponent as PricingIcon } from "./Icons/pricing.svg";
import { ReactComponent as CreditsIcon } from "./Icons/monthlyCredits.svg";

const iconList = [
  {
    name: "creditsUsage",
    icon: <CreditsIcon />,
    title: "Monthly data credits",
    custom: true
  },
  {
    name: "phoneCall",
    icon: <PhoneCallIcon />,
    title: "Make a call"
  },
  {
    name: "email",
    icon: <EmailIcon />,
    title: "Send Email"
  },
  {
    name: "pricing",
    icon: <PricingIcon />,
    title: "Pricing"
  }
];

export { iconList };
