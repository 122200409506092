import React, { useState, useEffect } from "react";
import { captchaStoreValue } from "Stores/captcha";
import Button from "Components/common/Button";
import DropdownMenu from "Components/common/DropDownMenu";
import InputTextBox from "Components/common/InputTextBox";
import ToggleBtnType from "Components/common/ToggleBtnType";
import { URLS, makeApi } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import userDetail from "Stores/userDetail";
import CustomTooltip from "Components/common/CustomTooltip";
import { confirmationPopupState } from "Components/common/confirmationPopup";

const defaultSmtpInput = {
  name: "",
  email: "",
  userName: "",
  smtpPassword: {
    password: "",
    showPassword: false
  },
  host: "",
  port: "",
  sslEnabled: false
};

const defaultImapInput = {
  name: "",
  email: "",
  imapPassword: {
    password: "",
    showPassword: false
  },
  host: "",
  port: "",
  sslEnabled: false
};

const defaultSenderReceiverVerified = {
  isSmtpVerified: false,
  isImapVerified: false
};

const defaultSenderErrorObj = {
  senderEmail: "",
  smtpHost: ""
};

export const ConnectSmtpImap = (props = {}) => {
  const {
    setSmtpImapConnect = () => {},
    reloadInbox = () => {},
    email = "",
    reconnectInboxId = ""
  } = props || {};
  const [smtpInputValue, setSmtpInputValue] = useState(defaultSmtpInput);
  const [imapInputValue, setImapInputValue] = useState(defaultImapInput);
  const [loader, setLoader] = useState("");
  const [connectionEstablished, setConnectionEstablished] = useState(
    defaultSenderReceiverVerified
  );
  const [showImapDropdown, setShowImapDropdown] = useState(false);
  const [showSmtpDropdown, setShowSmtpDropdown] = useState(false);
  const [senderErrorObj, setSenderErrorObj] = useState(defaultSenderErrorObj);

  const backAction = () => {
    setSmtpImapConnect(false);
    setSmtpInputValue(defaultSmtpInput);
    setImapInputValue(defaultImapInput);
    setLoader("");
    setConnectionEstablished(defaultSenderReceiverVerified);
  };

  const saveConnectedInbox = async token => {
    const { isSmtpVerified = false, isImapVerified = "" } =
      connectionEstablished || {};
    if (isSmtpVerified && isImapVerified) {
      setLoader("save");
      try {
        const config = {
          url: URLS.saveSmtpImapConnectedInbox,
          method: "POST",
          data: {
            smtp: constructPayload("smtp"),
            imap: constructPayload("imap"),
            token,
            reconnectInboxId
          }
        };
        const response = await makeApi(config);
        const { status = "", message = "", code = "" } = response.data || {};
        if (message?.toLowerCase() === "success" && status && +code === 200) {
          if (reconnectInboxId) {
            const config = {
              url: `${URLS.getDomainHealth}?inboxId=${reconnectInboxId}`,
              method: "GET"
            };
            makeApi(config);
          }
          Utils.mixpanelTrack(MXP_EVENT.SAVED_SMTP_IMAP_CONNECTION);
          reloadInbox(true);
        } else if (+code === 410) {
          toasterState.setToastMsg(
            `Failed. SMTP inbox is already connected to a different Salesgear account. Try with a different one.`,
            "fail"
          );
        } else if (+code === 411) {
          toasterState.setToastMsg(
            `Failed. IMAP inbox is already connected to a different Salesgear account. Try with a different one.`,
            "fail"
          );
        } else if (+code === 412) {
          toasterState.setToastMsg(
            `Failed. Please connect the same email account.`,
            "fail"
          );
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
        setLoader("");
      } catch (error) {
        setLoader("");
        console.error(`Save connected inbox failed`, error);
      }
    } else {
      toasterState.setToastMsg(
        "Please verify SMTP and IMAP connection to proceed",
        "fail"
      );
    }
  };

  const saveAction = () => {
    captchaStoreValue.executeCaptcha(saveConnectedInbox);
  };

  const handleSenderEmailAction = (key = "", value = "") => {
    if (key) {
      let tempVerifyObject = { isSmtpVerified: false };
      setSmtpInputValue({ ...smtpInputValue, [key]: value });
      setConnectionEstablished({
        ...connectionEstablished,
        ...tempVerifyObject
      });
    }
  };

  const handleReceiverEmailAction = (key = "", value = "") => {
    key && setImapInputValue({ ...imapInputValue, [key]: value });
    setConnectionEstablished({
      ...connectionEstablished,
      isImapVerified: false
    });
  };

  const viewPassword = (key = "", value = "") => {
    if (key === "smtp") {
      handleSenderEmailAction("smtpPassword", value);
    } else if (key === "imap") {
      handleReceiverEmailAction("imapPassword", value);
    }
  };

  const handlePortAction = (id = "", item = {}) => {
    const { subKey = false, value = "" } = item || {};
    if (id?.indexOf("imap") !== -1) {
      setImapInputValue({
        ...imapInputValue,
        ["port"]: value,
        ["sslEnabled"]: subKey
      });
      setShowImapDropdown(false);
    } else if (id?.indexOf("smtp") !== -1) {
      setSmtpInputValue({
        ...smtpInputValue,
        ["port"]: value,
        ["sslEnabled"]: subKey
      });
      setShowSmtpDropdown(false);
    }
  };

  const senderInputBoxList = [
    {
      name: "senderName",
      id: "senderName",
      keyValue: "name",
      type: "text",
      value: smtpInputValue?.name,
      change: handleSenderEmailAction,
      text: "Sender Name",
      placeHolder: "eg: John",
      defaultKey: true
    },
    {
      name: "senderEmail",
      id: "senderEmail",
      keyValue: "email",
      type: "email",
      value: smtpInputValue?.email,
      change: handleSenderEmailAction,
      text: "Sender Email Address",
      placeHolder: `eg: john@salesgear.io`,
      defaultKey: true,
      className: reconnectInboxId ? "disableInput" : "",
      readOnly: reconnectInboxId ? true : false,
      err: senderErrorObj["senderEmail"]
    },
    {
      name: "userName",
      id: "userName",
      keyValue: "userName",
      type: "text",
      value: smtpInputValue?.userName,
      change: handleSenderEmailAction,
      text: "User Name",
      placeHolder: "eg: John",
      defaultKey: true,
      className: reconnectInboxId ? "disableInput" : "",
      readOnly: reconnectInboxId ? true : false
    },
    {
      name: "smtpPassword",
      id: "smtpPassword",
      keyValue: "smtpPassword",
      type: "password",
      value: smtpInputValue?.smtpPassword,
      change: (key, value) => {
        handleSenderEmailAction(key, {
          ...smtpInputValue?.smtpPassword,
          ["password"]: value
        });
      },
      text: "Password",
      className: "password",
      isPassword: true,
      placeHolder: "Password",
      showPasswordAction: () => {
        viewPassword("smtp", {
          ...smtpInputValue?.smtpPassword,
          showPassword: !smtpInputValue?.smtpPassword?.showPassword
        });
      },
      defaultKey: true
    },
    {
      name: "smtpHost",
      id: "smtpHost",
      keyValue: "host",
      type: "text",
      value: smtpInputValue?.host,
      change: handleSenderEmailAction,
      text: "SMTP Host",
      placeHolder: "eg: smtp.gmail.com",
      defaultKey: true,
      err: senderErrorObj["smtpHost"]
    }
  ];

  const receiverInputBoxList = [
    {
      name: "receiverName",
      id: "receiverName",
      keyValue: "name",
      type: "text",
      value: imapInputValue?.name,
      change: handleReceiverEmailAction,
      text: "Receiver Name",
      placeHolder: "eg: John",
      defaultKey: true
    },
    {
      name: "receiverEmail",
      id: "receiverEmail",
      keyValue: "email",
      type: "email",
      value: imapInputValue?.email,
      change: handleReceiverEmailAction,
      text: "Receiver Email Address",
      placeHolder: `eg: john@salesgear.io`,
      defaultKey: true
    },
    {
      name: "imapPassword",
      id: "imapPassword",
      keyValue: "imapPassword",
      type: "password",
      value: imapInputValue?.imapPassword,
      change: (key, value) => {
        handleReceiverEmailAction(key, {
          ...imapInputValue?.imapPassword,
          ["password"]: value
        });
      },
      text: "Password",
      className: "password",
      isPassword: true,
      placeHolder: "Password",
      showPasswordAction: () => {
        viewPassword("imap", {
          ...imapInputValue?.imapPassword,
          showPassword: !imapInputValue?.imapPassword?.showPassword
        });
      },
      defaultKey: true
    },
    {
      name: "imapHost",
      id: "imapHost",
      keyValue: "host",
      type: "text",
      value: imapInputValue?.host,
      change: handleReceiverEmailAction,
      text: "IMAP Host",
      placeHolder: "eg: imap.gmail.com",
      defaultKey: true
    }
  ];

  const buttonList = [
    {
      id: "backToConnectedInbox",
      name: "backToConnectedInbox",
      btnText: "Back",
      type: "button",
      className: "backToConnectInbox",
      method: backAction,
      loading: false
    },
    {
      id: "saveSmtpImapConnection",
      name: "saveSmtpImapConnection",
      btnText: "Save",
      type: "button",
      className: `saveSmtpImapConnection ${
        connectionEstablished?.isSmtpVerified &&
        connectionEstablished?.isImapVerified
          ? ""
          : "disabled"
      } ${loader?.toLowerCase() === "save" ? "loading" : ""}`,
      method: saveAction,
      loading: loader?.toLowerCase() === "save"
    }
  ];

  const smtpOptions = [
    {
      value: 25,
      subKey: false,
      key: "smtpPort25",
      method: handlePortAction
    },
    {
      value: 465,
      subKey: true,
      key: "smtpPort465",
      method: handlePortAction
    },
    {
      value: 2525,
      subKey: true,
      key: "smtpPort2525",
      method: handlePortAction
    },
    {
      value: 587,
      subKey: false,
      key: "smtpPort587",
      method: handlePortAction
    }
  ];

  const imapOptions = [
    {
      value: 993,
      subKey: true,
      key: "imapPort993",
      method: handlePortAction
    },
    {
      value: 143,
      subKey: false,
      key: "imapPort143",
      method: handlePortAction
    }
  ];

  const constructPayload = (id = "", token = "") => {
    if (id === "smtp") {
      const {
        email = "",
        name = "",
        host = "",
        userName = "",
        smtpPassword = {},
        port = "",
        sslEnabled = false
      } = smtpInputValue || {};
      return {
        email,
        senderName: name,
        host,
        port,
        userName,
        password: smtpPassword?.password,
        sslEnabled
      };
    } else if (id === "imap") {
      const {
        email = "",
        name = "",
        host = "",
        imapPassword = {},
        port = "",
        sslEnabled = false
      } = imapInputValue || {};
      return {
        email,
        userName: email,
        senderName: name,
        host,
        port,
        password: imapPassword?.password,
        sslEnabled
      };
    }
    return null;
  };

  const testConnection = async (token = "", id = "") => {
    let payload = constructPayload(id?.toLowerCase());
    if (!loader && payload && Object.keys(payload)?.length && token) {
      setLoader(id?.toLowerCase());
      try {
        const config = {
          url: URLS.testSmtpImapConnection,
          method: "POST",
          data: { [id?.toLowerCase()]: payload, token }
        };
        const response = await makeApi(config);
        const {
          status = "",
          message = "",
          code = "",
          replyToEmail = ""
        } = response?.data || {};
        if (message?.toLowerCase() === "success" && status && +code === 200) {
          Utils.mixpanelTrack(
            MXP_EVENT[
              `TEST_${
                id?.toLowerCase() === "smtp" ? "SMTP" : "IMAP"
              }_CONNECTION_PERFORMED`
            ]
          );
          if (id?.toLowerCase() === "smtp") {
            handleSmtpConnectionSuccess(replyToEmail, payload);
          }
          setConnectionEstablished({
            ...connectionEstablished,
            isSmtpVerified:
              connectionEstablished?.isSmtpVerified ||
              id?.toLowerCase() === "smtp",
            isImapVerified:
              connectionEstablished?.isImapVerified ||
              id?.toLowerCase() === "imap"
          });
        } else {
          toasterState.setToastMsg(
            id?.toLowerCase() === "smtp"
              ? `SMTP connection failed. Check your configuration details & try again.`
              : "IMAP connection failed. Check your configuration details & try again.",
            "fail",
            JSON.stringify(message)
          );
        }
        setLoader("");
      } catch (error) {
        setLoader("");
        console.error(`${id} test connection verification failed`, error);
      }
    }
  };

  const testImapConnection = async (token = "") => {
    testConnection(token, "imap");
  };

  const testSmtpConnection = async (token = "") => {
    const { userFeatureFlag = {} } = userDetail || {};
    if (
      userFeatureFlag?.teamId === "670bd2b9e0ddeb0045368f15" ||
      !checkSmtpError()
    ) {
      testConnection(token, "smtp");
    }
  };

  const checkSmtpError = () => {
    const { email = "", host = "" } = smtpInputValue || {};
    const isEmailError = email.includes("gmail");
    const isHostError = host.includes("gmail");
    if (isHostError || isEmailError) {
      toasterState.setToastMsg(
        "Oops! You cannot connect a Gmail account. Please connect a different email account",
        "failure"
      );
    }
    return isEmailError || isHostError;
  };

  const handleTestAction = (id = "") => {
    if (!loader) {
      let funcParam =
        id?.toLowerCase() === "smtp" ? testSmtpConnection : testImapConnection;
      captchaStoreValue.executeCaptcha(funcParam);
    }
  };

  const validateSmtpField = () => {
    const {
      email = "",
      name = "",
      host = "",
      smtpPassword = {},
      port = ""
    } = smtpInputValue || {};
    return email && name && host && smtpPassword.password && port;
  };

  const validateImapField = () => {
    const {
      email = "",
      name = "",
      host = "",
      imapPassword = {},
      port = ""
    } = imapInputValue || {};
    return email && name && host && imapPassword.password && port;
  };

  const handleSmtpInputValue = (checked, id) => {
    setSmtpInputValue({
      ...smtpInputValue,
      port:
        checked === smtpInputValue?.sslEnabled
          ? !checked
            ? 465
            : 25
          : checked
          ? 465
          : 25,
      ["sslEnabled"]:
        checked === smtpInputValue?.sslEnabled ? !checked : checked
    });
  };

  const handleImapInputValue = (checked, id) => {
    setImapInputValue({
      ...imapInputValue,
      port:
        checked === imapInputValue?.sslEnabled
          ? !checked
            ? 993
            : 143
          : checked
          ? 993
          : 143,
      ["sslEnabled"]:
        checked === imapInputValue?.sslEnabled ? !checked : checked
    });
  };

  const handleSmtpConnectionSuccess = replyToEmail => {
    // when testing sendgrid smtp, we will return a replytoEmail to map in imap.
    if (replyToEmail) {
      handleReceiverEmailAction("email", replyToEmail);
    } else {
      const { email = "", name = "", smtpPassword = {} } = smtpInputValue || {};
      setImapInputValue({
        ...imapInputValue,
        email,
        name,
        imapPassword: smtpPassword
      });
    }
  };

  useEffect(() => {
    if (email) {
      setSmtpInputValue({
        ...defaultImapInput,
        email: email,
        userName: email,
        smtpPassword: {}
      });
    }
  }, [email]);

  return (
    <div className="smtpImapConnectPage">
      <div className="smtpImapContentBody">
        <div className="stmpImapTitle">
          <h4>Connect SMTP/IMAP</h4>
          <p>
            Configure your SMTP/IMAP email account to send emails from Salesgear
          </p>
        </div>
        <div className="twoColLayout">
          <div className="smtpEmailsForm">
            <EmailVerifyButton
              title="Sender"
              keyValue="smtp"
              emptySpace={true}
              isVerified={connectionEstablished.isSmtpVerified}
              testCbk={handleTestAction}
              loader={loader?.toLowerCase() === "smtp"}
              enableTestBtn={validateSmtpField()}
            />
            {senderInputBoxList.map(item => (
              <InputTextBox {...item} key={item?.id} />
            ))}
            <DropdownButton
              label="SMTP Port"
              id="smtpPort"
              defaultValue={smtpInputValue?.port}
              options={smtpOptions}
              showDropdown={showSmtpDropdown}
              setShowDropdown={setShowSmtpDropdown}
            />
            <div className="toggleActionSection">
              <ToggleBtnType
                id="smtp"
                defaultValue={smtpInputValue?.sslEnabled}
                lastText="Enable SSL"
                isAllowed={true}
                pageType="Connect smtp/imap"
                cbk={handleSmtpInputValue}
              />
            </div>
          </div>
          <div
            className={`imapEmailsForm ${
              !connectionEstablished?.isSmtpVerified ? "disableImap" : ""
            } `}
          >
            <EmailVerifyButton
              title="Receiver"
              keyValue="imap"
              isVerified={connectionEstablished?.isImapVerified}
              testCbk={handleTestAction}
              loader={loader?.toLowerCase() === "imap"}
              enableTestBtn={validateImapField()}
            />
            {receiverInputBoxList.map(item => (
              <InputTextBox {...item} key={item?.id} />
            ))}
            <DropdownButton
              label="IMAP Port"
              id="imapPort"
              defaultValue={imapInputValue?.port}
              options={imapOptions}
              showDropdown={showImapDropdown}
              setShowDropdown={setShowImapDropdown}
            />
            <div className="toggleActionSection">
              <ToggleBtnType
                id="imap"
                defaultValue={imapInputValue?.sslEnabled}
                lastText="Enable SSL"
                isAllowed={true}
                pageType="Connect smtp/imap"
                cbk={handleImapInputValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="smtpImapfooter">
        {buttonList?.map(item => {
          const {
            id = "",
            name = "",
            btnText = "",
            type = "",
            className = "",
            method = () => {},
            loading = false
          } = item || {};
          return (
            <Button
              key={id}
              id={id}
              name={name}
              btnText={btnText}
              type={type}
              className={className}
              click={method}
              loader={loading}
            />
          );
        })}
      </div>
    </div>
  );
};

const EmailVerifyButton = props => {
  const {
    title = "",
    loader = false,
    isVerified = false,
    enableTestBtn = false,
    keyValue = "",
    testCbk = () => {}
  } = props || {};

  return (
    <div className="colTiteAction">
      <div className="titeAction">
        <h6 className="title">{title} Email</h6>
        {isVerified ? (
          <div className="verfiedMsg">
            <i className="material-icons tickIcon">check_circle</i>
            <div className="text">Verified</div>
          </div>
        ) : (
          <Button
            key={`test${title}Email`}
            id={`test${title}Email`}
            name={`test${title}Email`}
            btnText="Test"
            type="button"
            className={`test${title}Email ${loader ? "loading" : ""} ${
              enableTestBtn ? "" : "disabled"
            }`}
            click={() => {
              !isVerified && testCbk(keyValue);
            }}
            loader={loader}
          />
        )}
      </div>
    </div>
  );
};

const DropdownButton = props => {
  const {
    label = "",
    id = "",
    defaultValue = "",
    err = "",
    options = [],
    showDropdown = false,
    setShowDropdown = () => {}
  } = props || {};

  return (
    <div className="dropdownButtonOption">
      <label
        htmlFor={id}
        onClick={() => setShowDropdown(!showDropdown)}
        className={!showDropdown && !defaultValue ? "adjustLabel" : ""}
      >
        {label}
      </label>
      <div
        className="inputValueIcon"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <span className="activeValue">{defaultValue}</span>
        <i className="material-icons-outlined icon">expand_more</i>
      </div>
      {showDropdown && (
        <>
          <DropdownMenu options={options} referenceId={id} />
          <div
            className="dropdownMask"
            onClick={() => setShowDropdown(false)}
          />
        </>
      )}
      {err && <span>{err}</span>}
    </div>
  );
};

export default ConnectSmtpImap;
