import React from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import Button from "Components/common/Button";

class SyncConfirmationPopupState {
  @observable showPopup = false;
  @observable callback = () => {};
  @observable criteria = "";
  @observable loading = false;
  @observable syncNowDisabled = false;
  @observable hasUpdate = false;

  @action
  setShowPopup = (value = "") => {
    this.showPopup = value;
  };

  @action
  setCallback = (callback = () => {}) => {
    this.callback = callback;
  };

  @action
  setCriteria = (value = "") => {
    this.criteria = value;
  };

  @action
  setLoading = (value = false) => {
    this.loading = value;
  };

  @action
  setSyncNowDisabled = (value = false) => {
    this.syncNowDisabled = value;
  };

  @action
  setHasUpdate = (value = false) => {
    this.hasUpdate = value;
  };
}

const syncConfirmationPopupState = new SyncConfirmationPopupState();

const SyncConfirmationPopup = observer(() => {
  const startSyncCrmBtn = {
    id: "startSyncCrm",
    name: "startSyncCrm",
    btnText: "Start Sync",
    type: "button",
    className: `startSyncCrm ${
      syncConfirmationPopupState.loading ? "loading" : ""
    }`,
    click: () => {
      syncConfirmationPopupState.callback();
    },
    loader: syncConfirmationPopupState.loading
  };

  const cancelCrmBtn = {
    id: "cancelCrm",
    name: "cancelCrm",
    btnText: "Cancel",
    type: "button",
    className: `cancelBtn`,
    click: () => {
      syncConfirmationPopupState.setShowPopup(false);
    },
    loader: false
  };

  return (
    syncConfirmationPopupState.showPopup && (
      <>
        <section className="syncConfirmationPopupWrapper">
          <div className="syncConfirmationPopupContainer card">
            <i
              className="material-icons closeIcon"
              onClick={() => {
                syncConfirmationPopupState.setShowPopup(false);
              }}
            >
              close
            </i>
            <div className="popupHeading">Sync Confirmation</div>
            <div className="popupContent">
              Sync will start pulling contacts matching the below criteria once
              you confirm.
            </div>
            <p className="criteriaWrapper">
              <span className="criteriaHeading">{`Criteria : `}</span>
              <p className="popupCriteria">
                {`${
                  syncConfirmationPopupState?.criteria
                    ? syncConfirmationPopupState?.criteria
                    : "All Contacts"
                }`}
              </p>
            </p>
            <div className="actionBtn">
              <Button {...cancelCrmBtn} />
              <Button {...startSyncCrmBtn} />
            </div>
          </div>
        </section>
        <div className="syncConfirmationPopupOverlay" />
      </>
    )
  );
});

export { SyncConfirmationPopup, syncConfirmationPopupState };
export default SyncConfirmationPopup;
