import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { MXP_EVENT } from "Static/MixPanelEvents";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { ReactComponent as EmailCreditsSvg } from "Assets/svg/creditsOverview/EmailCredits.svg";
import { ReactComponent as PhoneCreditsSvg } from "Assets/svg/creditsOverview/PhoneCredits.svg";
import { ReactComponent as ForwardArrowSvg } from "Assets/svg/arrow-forward.svg";
import { ReactComponent as ResetCreditsSvg } from "Assets/svg/creditsOverview/resetcredits.svg";
import { ReactComponent as RemoveDailyLimitSvg } from "Assets/svg/creditsOverview/removeDailyLimit.svg";
import { priceRangeSliderPopupState } from "../popup/PriceRangeSliderPopup/main";
import CreditUsageTimer from "Pages/Dashboard/settings/CreditsUsageTimer";
import userDetail from "Stores/userDetail";
import PricingStore from "Stores/Pricing";
import PaywallUtils from "Utils/PaywallUtils";

class CreditUsagePopupState {
  @observable creditsData = { dailyLimit: true };
  @observable showPopup = false;
  @observable creditsDataLoading = true;
  @observable upgradeToRemoveDailyLimit = false;

  @action
  setCreditsData = (value = {}) => {
    this.creditsData = value;
  };

  @action
  setCreditsDataLoading = (value = false) => {
    this.creditsDataLoading = value;
  };

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setUpgradeToRemoveDailyLimit = (value = false) => {
    this.upgradeToRemoveDailyLimit = value;
  };

  @action
  getCreditsUsage = async () => {
    try {
      const config = {
        url: URLS.getCreditsUsage
      };
      const res = await makeApi(config);
      setTimeout(() => {
        this.setCreditsDataLoading(false);
      }, 500);
      if (res?.data && Object.keys(res?.data)?.length > 0) {
        this.setCreditsData(res?.data);
      }
    } catch (err) {
      console.error(err, "Getting Credit Usage ERROR!");
    }
    return;
  };
}

const creditUsagePopupState = new CreditUsagePopupState();

const LinearProgress = ({ width = 0, bgColor = "#38B892" }) => {
  return (
    <div className="linearProgressBar">
      <div className="backgroundProgress"></div>
      <div
        className={`linearProgress`}
        style={{
          width: `${width}%`,
          background: `${bgColor}`
        }}
      ></div>
    </div>
  );
};

const CreditsGrid = observer((props = {}) => {
  const {
    limitTitle = "Daily",
    total = 0,
    used = 0,
    remaining = 0,
    bgColor = ""
  } = props || {};
  const limitCount = `${Utils.formatNumberToString(
    used
  )} / ${Utils.formatNumberToString(total)}`;

  return (
    <div className="creditsGridWrapper">
      <div className="flexWrapper">
        <p className="creditsLimitTitle">{limitTitle}</p>
        <p
          className={`creditsLimitCount ${
            creditUsagePopupState.creditsDataLoading ? "linear-background" : ""
          }`}
        >
          {!creditUsagePopupState.creditsDataLoading && limitCount}
        </p>
      </div>
      <div className="linearProgressWrapper">
        {creditUsagePopupState.creditsDataLoading ? (
          <div className="linear-background"></div>
        ) : (
          <LinearProgress
            width={`${(remaining / total) * 100}`}
            bgColor={bgColor}
          />
        )}
      </div>
      {(remaining > 0 || creditUsagePopupState.creditsDataLoading) && (
        <div className={"remainingCount"}>
          <div
            className={`${
              creditUsagePopupState.creditsDataLoading
                ? "linear-background"
                : ""
            }`}
          >
            {!creditUsagePopupState.creditsDataLoading &&
              `${Utils.formatNumberToString(remaining)} remaining`}
          </div>
        </div>
      )}
    </div>
  );
});

const PhoneCredits = observer(({ handlePricingOrPayment = () => {} }) => {
  const creditsData = creditUsagePopupState.creditsData || {};
  const {
    inFreePlan = false,
    trialEnabled = false,
    planName = "",
    isAnnual = false
  } = userDetail?.userFeatureFlag || {};
  const total = isAnnual
    ? creditsData.phoneCreditsAnnualLimit
    : creditsData.phoneCreditsMonthlyLimit;
  const usedLimit = isAnnual
    ? creditsData.annualPhoneCreditsUsed
    : creditsData.monthlyPhoneCreditsUsed;

  const handleGetMoreClick = () => {
    Utils.mixpanelTrack(MXP_EVENT.GET_MORE_CREDITS, {
      creditType: "Phone number credits"
    });
    if (trialEnabled && !inFreePlan) {
      priceRangeSliderPopupState.setShowPopup(true);
    } else if (inFreePlan) {
      handlePricingOrPayment();
    } else {
      if (creditsData.dailyLimit) {
        priceRangeSliderPopupState.setShowPopup(true);
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "data_AddCreditsToDataSearch",
          true,
          planName,
          inFreePlan
        );
      }
    }
    creditUsagePopupState.setShowPopup(false);
  };

  return (
    <div className="phoneCreditsWrapper">
      <div className="flexWrapperWithSpace">
        <div className="flexWrappers">
          <span className="phoneCreditsIcon">
            <PhoneCreditsSvg />
          </span>
          <p className="creditsText">Phone number Credits</p>
        </div>
        <div className="getMoreText" onClick={handleGetMoreClick}>
          Get More
        </div>
      </div>
      <CreditsGrid
        limitTitle={isAnnual ? "Yearly" : "Monthly"}
        total={total}
        used={usedLimit}
        remaining={total - usedLimit}
        bgColor="#1EB9D5"
      />
    </div>
  );
});

const EmailCredits = observer(({ handlePricingOrPayment = () => {} }) => {
  const history = useHistory();
  const {
    inFreePlan = false,
    trialEnabled = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};
  const creditsData = creditUsagePopupState.creditsData || {};

  const handleGetMoreClick = () => {
    Utils.mixpanelTrack(MXP_EVENT.GET_MORE_CREDITS, {
      creditType: "Email credits"
    });
    if (trialEnabled && !inFreePlan) {
      history.push("/pricing");
    } else if (inFreePlan) {
      handlePricingOrPayment();
    } else {
      if (creditsData?.dailyLimit) {
        history.push("/pricing");
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "data_AddCreditsToDataSearch",
          true,
          planName,
          inFreePlan
        );
      }
    }
    creditUsagePopupState.setShowPopup(false);
  };

  return (
    <div className="emailCreditsWrapper">
      <div className="flexWrapperWithSpace">
        <div className="flexWrappers">
          <span className="emailCreditsIcon">
            <EmailCreditsSvg />
          </span>
          <p className="creditsText">Email Credits</p>
        </div>
        <div className="getMoreText" onClick={handleGetMoreClick}>
          Get More
        </div>
      </div>
      <CreditsGrid
        total={creditsData.emailCreditsDailyLimit}
        remaining={
          creditsData.emailCreditsDailyLimit - creditsData.dailyEmailCreditsUsed
        }
        used={creditsData.dailyEmailCreditsUsed}
        bgColor={"#38B892"}
      />
      <CreditsGrid
        limitTitle={"Monthly"}
        total={creditsData.emailCreditsMonthlyLimit}
        remaining={
          creditsData.emailCreditsMonthlyLimit -
          creditsData.monthlyEmailCreditsUsed
        }
        used={creditsData.monthlyEmailCreditsUsed}
        bgColor={"#38B892"}
      />
    </div>
  );
});

const CreditUsagePopup = observer(() => {
  const history = useHistory();
  const { inFreePlan = false, trialEnabled = false } =
    userDetail?.userFeatureFlag || {};
  const creditsData = creditUsagePopupState.creditsData || {};

  const handlePricingOrPayment = () => {
    if ((trialEnabled && !inFreePlan) || inFreePlan) {
      let unlimitedPlan = PricingStore?.activePlanData.filter(
        item => item.displayName === "Unlimited"
      )[0];

      PricingStore.setPaymentFormInfo(unlimitedPlan);
      localStorage.setItem("proceedToPay", true);
      history.push("/pricing/payment");
    } else {
      if (creditsData?.dailyLimit) {
        history.push("/pricing");
      }
    }
    creditUsagePopupState.setShowPopup(false);
  };

  const getTimeDifference = () => {
    const now = new Date();
    const todayMidnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0,
      0,
      0
    );

    const timeDifferenceInMilliseconds = todayMidnight - now;
    return timeDifferenceInMilliseconds;
  };

  useEffect(() => {
    if (creditUsagePopupState.showPopup) {
      creditUsagePopupState.setCreditsDataLoading(true);
      creditUsagePopupState.getCreditsUsage();
      Utils.mixpanelTrack(MXP_EVENT.CREDITS_HOVER_POPUP);
    }
  }, [creditUsagePopupState.showPopup]);

  useEffect(() => {
    PricingStore.getActivePlanDetails();
  }, []);

  return (
    creditUsagePopupState.showPopup && (
      <div>
        <div
          className="creditUsageMask"
          onMouseEnter={() => creditUsagePopupState.setShowPopup(true)}
          onMouseLeave={() => creditUsagePopupState.setShowPopup(false)}
        />
        <div
          className={`creditUsagePopupWrapper ${
            creditUsagePopupState.upgradeToRemoveDailyLimit
              ? "removeDailyLimitWrapper"
              : ""
          } `}
          onMouseEnter={() => creditUsagePopupState.setShowPopup(true)}
          onMouseLeave={() => creditUsagePopupState.setShowPopup(false)}
        >
          <h4>Credits overview</h4>
          {creditUsagePopupState.creditsDataLoading ? (
            <div className="creditsUsageHead linear-background"></div>
          ) : (
            creditsData.dailyLimit && (
              <div
                className={`creditsUsageHead ${
                  creditUsagePopupState.upgradeToRemoveDailyLimit
                    ? "borderHeader"
                    : ""
                }`}
                onMouseEnter={() =>
                  creditUsagePopupState.setUpgradeToRemoveDailyLimit(true)
                }
                onMouseLeave={() =>
                  creditUsagePopupState.setUpgradeToRemoveDailyLimit(false)
                }
              >
                {creditUsagePopupState.upgradeToRemoveDailyLimit ? (
                  <div
                    className="removeDailyLimit"
                    onClick={() => {
                      Utils.mixpanelTrack(MXP_EVENT.REMOVE_DAILY_LIMIT);
                      handlePricingOrPayment();
                    }}
                  >
                    <span className="resetCreditsIcon">
                      <RemoveDailyLimitSvg />
                    </span>
                    <p className="limitText">
                      {inFreePlan
                        ? "Upgrade Now to Get More Credits."
                        : "Upgrade to remove daily limit"}
                    </p>
                    <span className="arrowIcon">
                      <ForwardArrowSvg />
                    </span>
                  </div>
                ) : (
                  <>
                    <span className="resetCreditsIcon">
                      <ResetCreditsSvg />
                    </span>
                    <p className="limitText">
                      {inFreePlan
                        ? "0 credits left. Upgrade for more credits."
                        : "Daily Credit Limit Resets in:"}
                    </p>
                    {!inFreePlan && (
                      <CreditUsageTimer initialseconds={getTimeDifference()} />
                    )}
                  </>
                )}
              </div>
            )
          )}
          <div className="creditUsagePopupContent">
            <EmailCredits handlePricingOrPayment={handlePricingOrPayment} />
            <PhoneCredits handlePricingOrPayment={handlePricingOrPayment} />
          </div>
          <div
            className="creditFooterText"
            onClick={() => {
              creditUsagePopupState.setShowPopup(false);
              history.push("/settings/creditUsage");
            }}
          >
            <span className="">View details in credit history</span>
            <ForwardArrowSvg />
          </div>
        </div>
      </div>
    )
  );
});

export { CreditUsagePopup, creditUsagePopupState };
export default CreditUsagePopup;
