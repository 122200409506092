import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import {
  getContactPurchasePayload,
  handlePurchaseResponse,
  getSelectedContactsPayload,
  getAddContactsToSequencePayload
} from "./DataSearchUtils";
import { observable, action } from "mobx";
import { customListState } from "Pages/Dashboard/dataSearch/CustomList/state";
import { popupLoaderState } from "Pages/Dashboard/dataSearch/PopupLoader";
import { Utils } from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { exportProcessingState } from "Components/popup/ExportProcessing";
import { ContactCardFunction } from "Components/ContactCard/ContactCardFunction";
import { IntercomTour } from "Static/constant";

let cancelRequest = null;

const checkAndCancelRequest = () => {
  if (cancelRequest !== null) {
    cancelRequest("cancel");
    cancelRequest = null;
  }
};

const cancelApiRequest = value => {
  cancelRequest = value;
};

class DataSearchState {
  @observable filterComponentData = {
    confidenceScore: [],
    excludeTeamOwns: true
  };
  @observable contactTableData = {};
  @observable selectedContactData = [];
  @observable pageLimit = 10;
  @observable currentPage = 1;
  @observable selectedFilteredList = [];
  @observable industriesList = [];
  @observable isFilterApplied = false;
  @observable clearAll = false;
  @observable rangeSelected = "";
  @observable isSelectionReset = false;
  @observable isFromSeoPage = false;
  @observable isEmailViewedFromSeoPage = false;
  @observable hasData = false;
  @observable showInitialPage = true;

  @action
  setClearAll = val => {
    this.clearAll = val;
  };

  @action
  setShowInitialPage = val => {
    this.showInitialPage = val;
  };

  @action
  setSelectedContactData = val => {
    this.selectedContactData = val;
  };

  @action
  setPageLimit = val => {
    this.pageLimit = val;
  };

  @action
  setCurrentPage = val => {
    this.currentPage = val;
  };

  @action
  setFilterComponentData = val => {
    this.filterComponentData = val;
    this.setFilterApplied(true);
  };

  @action
  setContactTableData = val => {
    this.contactTableData = val;
  };

  @action
  setSelectedFilteredList = val => {
    this.selectedFilteredList = val;
  };

  @action
  setIndustriesList = val => {
    this.industriesList = val;
  };

  @action
  setFilterApplied = val => {
    this.isFilterApplied = val;
  };

  @action
  setRangeSelected = (obj = "") => {
    this.rangeSelected = obj;
  };

  @action
  setSelectionReset = (value = false) => {
    this.isSelectionReset = value;
  };

  @action
  setIsFromSeoPage = value => {
    this.isFromSeoPage = value;
  };

  @action
  setHasData = value => {
    this.hasData = value;
  };

  @action
  setIsEmailViewedFromSeoPage = value => {
    this.isEmailViewedFromSeoPage = value;
  };

  @action
  searchPageData = async (storePrevValue = false) => {
    const config = {
      url: URLS.searchDataPage,
      method: "POST",
      data: {
        ...this.filterComponentData,
        limit: this.pageLimit,
        pageNo: this.currentPage
      }
    };
    checkAndCancelRequest();
    dataSearchState.setShowInitialPage(false);
    let res = await makeApi(config, cancelApiRequest);
    if (
      res?.message?.message?.toLowerCase() === "cancel" ||
      res?.message?.toLowerCase() === "cancel"
    ) {
      Utils.mixpanelTrack(MXP_EVENT.SEARCH_PERFORMED, {
        pageType: PAGE_TYPE.discover,
        searchCriteria: config?.data || {},
        results: res?.data || {},
        Network: "Request cancelled"
      });
      console.error("Request cancelled");
    } else {
      if (storePrevValue) {
        const updatedResData = [
          ...this.contactTableData?.contacts,
          ...res?.data?.contacts
        ];
        this.setIsFromSeoPage(false);
        this.setContactTableData({
          ...res?.data,
          contacts: updatedResData,
          totalContacts: res?.data?.totalContacts + 1
        });
      } else {
        this.setContactTableData(res?.data || {});
      }
      Utils.mixpanelTrack(MXP_EVENT.SEARCH_PERFORMED, {
        pageType: PAGE_TYPE.discover,
        searchCriteria: config?.data || {},
        results: res?.data || {}
      });
    }
  };

  @action
  purchaseSingleContact = async (data = {}, history = []) => {
    const config = {
      url: URLS.purchaseContact,
      method: "POST",
      data
    };
    let res = await makeApi(config);
    if (res?.status === 200) {
      if (this.isFromSeoPage) {
        this.setIsEmailViewedFromSeoPage(true);
        dataSearchState.setIsFromSeoPage(true);
        setTimeout(() => {
          window.Intercom("startTour", IntercomTour.viewEmailFromSeoPage);
        }, 2000);
      }
      handlePurchaseResponse(res?.data, "", history);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
    if (data?.emailPurchase) {
      Utils.mixpanelTrack(MXP_EVENT["VIEW_EMAIL_SINGLE_PERFORMED"], {
        pageType: PAGE_TYPE.discover,
        success: res?.status === 200
      });
    }
  };

  @action
  purchaseBulkContact = async (purchaseType, history) => {
    const config = {
      url: URLS.purchaseContact,
      method: "POST",
      data: this.getBulkPurchaseContactPayload(purchaseType)
    };
    let res = await makeApi(config);
    if (res?.status === 200) {
      if (this.isFromSeoPage) {
        this.setIsEmailViewedFromSeoPage(true);
        dataSearchState.setIsFromSeoPage(true);
        if (res?.data?.contacts?.length > 0) {
          res.data.contacts[0].isSelected = true;
        }
      }
      handlePurchaseResponse(res?.data, "", history);
    } else {
      popupLoaderState.setShowPopup(false);
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
    Utils.mixpanelTrack(
      MXP_EVENT[
        config?.data?.emailPurchase
          ? "VIEW_EMAIL_BULK_PERFORMED"
          : "VIEW_EMAIL_PHONE_BULK_PERFORMED"
      ],
      {
        pageType: PAGE_TYPE.discover,
        success: res?.status === 200,
        searchCriteria: config?.data || {}
      }
    );
  };

  @action
  addContactToList = async list => {
    const config = {
      url: URLS.addContactToList,
      method: "POST",
      data: this.getPurchaseContactPayload(list, "email")
    };
    let res = await makeApi(config);
    if (res?.status === 200) {
      customListState.getContactList();
      this.setIsFromSeoPage(false);
      this.searchPageData();
      handlePurchaseResponse(res?.data, list?.name);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
    Utils.mixpanelTrack(MXP_EVENT.ADD_TO_LIST_PERFORMED, {
      pageType: PAGE_TYPE.discover,
      listName: list?.name,
      searchCriteria: config?.data || {},
      success: res?.status === 200
    });
  };

  getPurchaseContactPayload = (list, purchaseType) => {
    const ids = [];
    this.selectedContactData.map(id => {
      ids.push(id);
    });
    return {
      purchasePayload: getContactPurchasePayload(ids, list?.id, purchaseType)
    };
  };

  getBulkPurchaseContactPayload = purchaseType => {
    const ids = [];
    this.selectedContactData.map(id => {
      ids.push(id);
    });
    return getContactPurchasePayload(ids, null, purchaseType);
  };

  @action
  searchFilterWithAPI = async (searchValue = "", autoCompleteVal = "") => {
    if (searchValue) {
      const urlObj = {
        companydomain: URLS.companyNameList,
        location: URLS.locationNameList,
        keywords: URLS.keywordNameList
      };
      let url = `${
        urlObj[autoCompleteVal?.toLowerCase()]
      }?search=${searchValue}`;
      const config = {
        url,
        method: "POST"
      };
      checkAndCancelRequest();
      let res = await makeApi(config, cancelApiRequest);
      return res;
    } else {
      return {};
    }
  };

  @action
  getIndustriesData = async () => {
    const config = { url: URLS.industriesList, method: "GET" };
    let res = await makeApi(config);
    const { data = [], status = "" } = res || {};
    if (status === 200) {
      this.setIndustriesList(data?.industries);
    }
  };

  @action
  exportContactsAsCSV = async () => {
    if (dataSearchState?.selectedContactData?.length) {
      try {
        exportProcessingState.setShowPopup(true);
        let payload = getSelectedContactsPayload(
          dataSearchState?.selectedContactData
        );
        payload.emailPurchase = true;
        const config = {
          url: URLS.exportContactsAsCSV,
          method: "POST",
          data: payload
        };
        let res = await makeApi(config);
        const { data = {}, status = "" } = res || {};
        if (status === 200 && Utils.hasObject(data)) {
          if (this.isFromSeoPage) {
            this.setIsEmailViewedFromSeoPage(true);
            dataSearchState.setIsFromSeoPage(true);
          }
          handlePurchaseResponse(data, "", "", "export");
        } else {
          exportProcessingState.setShowPopup(false);
          toasterState.setToastMsg(
            "Failed to download selected contacts. Please try again later.",
            "fail"
          );
        }
        Utils.mixpanelTrack(MXP_EVENT.EXPORT_CSV_PERFORMED, {
          pageType: PAGE_TYPE.discover,
          searchCriteria: payload,
          success: status === 200
        });
      } catch (err) {
        console.error("Failed to export contacts", err);
      }
    }
  };

  @action
  addContactsToSequence = async (sequenceId = "", currentSequence = "") => {
    if (sequenceId && dataSearchState?.selectedContactData?.length) {
      try {
        ContactCardFunction.uploadingContact();
        let payload = getAddContactsToSequencePayload(
          dataSearchState?.selectedContactData,
          sequenceId
        );
        const config = {
          url: URLS.dsAddToSequence,
          method: "POST",
          data: payload
        };
        let res = await makeApi(config);
        const { data = {}, status = "" } = res || {};
        if (status === 200 && Utils.hasObject(data)) {
          if (this.isFromSeoPage) {
            this.setIsEmailViewedFromSeoPage(true);
            dataSearchState.setIsFromSeoPage(true);
          }
          handlePurchaseResponse(data, "", "", "sequence", currentSequence);
        } else {
          ContactCardFunction.resetUploadingFunc();
          toasterState.setToastMsg(
            "Failed to download selected contacts. Please try again later.",
            "fail"
          );
        }
        Utils.mixpanelTrack(MXP_EVENT.ADD_TO_SEQ_PERFORMED, {
          pageType: PAGE_TYPE.discover,
          searchCriteria: payload,
          success: status === 200
        });
      } catch (err) {
        console.error("Failed to export contacts", err);
      }
    }
  };
}

const dataSearchState = new DataSearchState();

export { dataSearchState };
