import React from "react";
import { observer } from "mobx-react";
import ContactLeadCondition from "./ContactLeadCondition";
import ContactCondition from "./ContactCondition";

const AddContactWithCondition = observer(props => {
  const { fields = {}, type = "LEADS" } = props || {};

  return (
    <div className={"sectionMarginBottom addContactsWrapper"}>
      <div className="flexColumn">
        <div className="updateContactsCont">
          <span className="headingText">{`${
            type === "LEADS" ? "Import Leads" : "Import Contacts"
          }`}</span>
        </div>
        <span className="headingSubText">
          {`${type === "LEADS" ? "Leads" : "Contacts"}`} that match the below
          conditions will be imported from Salesforce CRM to Salesgear
        </span>
      </div>
      {type === "LEADS" ? (
        <ContactLeadCondition fields={fields} {...props} />
      ) : (
        <ContactCondition fields={fields} {...props} />
      )}
    </div>
  );
});

export { AddContactWithCondition };
export default AddContactWithCondition;
