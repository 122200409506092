import { action, observable, toJS } from "mobx";
import Utils from "../Utils/utils";

const defaultPopupValue = {
  contactData: "",
  dialerLogAfterCallEnd: true,
  performCbkFunction: () => {},
  pageType: "",
  isContactCard: false,
  isFromContactSection: false,
  selectedNumber: ""
};

class GlobalCallState {
  @observable showCallSlider = false;
  @observable popupValues = Utils.createObjWithoutRef(defaultPopupValue);

  @action
  setShowCallSlider = val => {
    this.showCallSlider = val;
  };

  @action
  setPopupValues = (obj = {}) => {
    this.popupValues = obj;
  };

  @action
  resetPopupValues = (appendContactData = false) => {
    this.showCallSlider = false;
    if (appendContactData) {
      this.popupValues = Utils.createObjWithoutRef({
        ...defaultPopupValue,
        contactData: this.popupValues.contactData
      });
    } else {
      this.popupValues = Utils.createObjWithoutRef(defaultPopupValue);
    }
  };
}
const globalCallState = new GlobalCallState();

export { globalCallState };
export default globalCallState;
