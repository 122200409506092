/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { Device } from "twilio-client";
import { toasterState } from "Components/common/toaster";
import { makeApi, URLS } from "Utils/apiURL";
import userDetail from "Stores/userDetail";
import DialerServiceData, {
  defaultDialerOption
} from "Stores/DialerServiceStore";
import { hideIntercomChat } from "Utils/Scripts/intercom";
import ContactInfo from "./DialerContactInfo";
import DialerKeypad from "./DialerKeypad";
import DialerButton from "./DialerButton";
import DialerDefaultNumber from "./DialerDefaultNumber";
import DialerCredits from "./DialerCredits";
import DialerLogsAfterCallEnd from "./DialerLogsAfterCallEnd";
import { dialerLogsPopupState } from "./DialerLogsAfterCallEndPopup";
import DialerUpgradeCreditsPopup from "./DialerUpgradeCreditsPopup";
import DialerButtonLoader from "./DialerButtonLoader";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import Utils from "Utils/utils";
import SliderHeader from "./SliderHeader";
import { callsStore } from "Stores/Calls";
import { cryptoKey } from "Static/constant";
import globalCallState from "Stores/GlobalCallState";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";

var CryptoJS = require("crypto-js");
let device = new Device();

const DialerSliderCard = observer(props => {
  const {
    data,
    slideAnimation = false,
    isBackArrow,
    backArrowCbk,
    dialerLogAfterCallEnd = true,
    performTaskCbk = () => {},
    isFromContactSection = false,
    pageType = "",
    isContactCard = false,
    selectedNumber = ""
  } = props || {};

  const {
    contactId: cId = "",
    phone,
    mobilePhone,
    otherPhone,
    firstName = "",
    lastName = "",
    name = "",
    id: contactId = ""
  } = data || {};

  const newPhoneArr =
    [...(phone || []), ...(mobilePhone || []), ...(otherPhone || [])]
      ?.flat(Infinity)
      ?.filter(element => {
        return element && !["[]", "[ ]"].includes(element);
      }) || [];

  const getNumberList = useCallback(() => {
    return selectedNumber?.length > 0 ? selectedNumber : newPhoneArr?.[0] || "";
  }, [mobilePhone, phone, otherPhone, selectedNumber]);

  const [loader, setLoader] = useState(true);
  const [tokenObj, setTokenObj] = useState(DialerServiceData.dialerTokenObj);
  const [dialerOption, setDialerOption] = useState({
    ...DialerServiceData?.dialerOption
  });
  const [callLogs, setCallLog] = useState(false);
  const [phoneNo, setPhoneNo] = useState(getNumberList());
  const [dialerCredits, setDialerCredits] = useState(
    userDetail.userFeatureFlag?.dialerCredits || 0
  );
  const [activeConnection, setActiveConnection] = useState(null);
  const [showUpgradeCredits, setUpgradeCredits] = useState(false);
  const [defaultNo, setDefaultNo] = useState(DialerServiceData.dialerDefaultNo);
  const [phoneNoEdited, setPhoneNoEdited] = useState(false);
  const [randomId, setRandomId] = useState("");
  const [closeBtnEnabled, setCloseBtnEnabled] = useState(true);
  const [isRecordingActivated, setRecordingActivated] = useState(false);
  const [toggleDropdown, setToggleDropDown] = useState(true);

  const resetAndReloadCallStatsPage = () => {
    dialerLogsPopupState.setShowEditCallLogs(false);
    pageType === "calls" &&
      showCallLogsSliderCard() &&
      callsStore.setIsLogUpdated(true);
  };

  const closeDialerCard = () => {
    if (!DialerServiceData.dialerOption.call) {
      setCallLog(false);
      DialerServiceData.setDialerCallLogs(false);
      DialerServiceData.setDialerTokenObj(null);
      setDialerOption({ ...defaultDialerOption });
      setPhoneNoEdited(false);
      DialerServiceData.setDialerOption({ ...defaultDialerOption });
      DialerServiceData.setDialerCallNotes("");
      DialerServiceData.setDialerCallStatus("");
      DialerServiceData.setDialerIvrKeypad(false);
      resetAndReloadCallStatsPage();
      globalCallState.resetPopupValues(true);
    } else {
      confirmationPopupState.setPopupValues({
        title: "Close Dialer?",
        content:
          "Are you sure you want to close?<br>Your call will get disconnected.",
        actionBtnText: "Yes",
        callback: () => {
          resetAndReloadCallStatsPage();
        }
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const hideUpgrade = () => {
    setUpgradeCredits(false);
  };

  const phoneNoErrorPrompt = () => {
    confirmationPopupState.setPopupValues({
      content:
        "Please enter a valid phone number. Make sure to prefix the number with plus symbol (+) and country code.<br/><br/><span class='phoneExample'>Examples: +44 20xxx xxxx, +91 9xxxx xxxxx, +1 415 5xxxxxx</span>",
      actionBtnText: "Ok",
      needCancelBtn: false,
      callback: () => {}
    });
    confirmationPopupState.setShowPopup(true);
  };

  const getDecryptCapabilityToken = token => {
    if (token) {
      const keyForCryptoJS = CryptoJS.enc.Base64.parse(cryptoKey);
      const decodeBase64 = CryptoJS.enc.Base64.parse(token);
      const decryptedData = CryptoJS.AES.decrypt(
        {
          ciphertext: decodeBase64
        },
        keyForCryptoJS,
        {
          mode: CryptoJS.mode.ECB
        }
      );
      return decryptedData.toString(CryptoJS.enc.Utf8);
    } else {
      return null;
    }
  };

  // Dialer Service Mutliple Action Event Button
  const call = () => {
    DialerServiceData.setDeviceStatus("");
    if (dialerCredits > 0.25) {
      const {
        capabilityToken = "",
        statusCode = "",
        configId = ""
      } = tokenObj || {};
      if (capabilityToken && +statusCode === 200) {
        setCloseBtnEnabled(isFromContactSection);
        if (phoneNo?.length > 6 && phoneNo?.length < 20) {
          if (phoneNo?.[0] === "+") {
            const params = {
              to_number: phoneNo,
              caller_id: parseInt(defaultNo),
              record: false,
              task_id: globalCallState?.popupValues?.taskId || "",
              memberId: userDetail?.userInfo?.memberId,
              contactId: cId || contactId,
              randomNo: getAndSaveRandomId()
            };
            if (device) {
              if (["globalheader"].includes(pageType?.toLowerCase())) {
                Utils.mixpanelTrack(MXP_EVENT.GLOBAL_HEADER_DIALER_USED, {
                  pageType: PAGE_TYPE.header,
                  type: "Calling"
                });
              } else {
                Utils.mixpanelTrack(MXP_EVENT.DIALER_USED, {
                  type: "Calling"
                });
              }
              device.connect(params);
              DialerServiceData.setDialerCallStatus("Calling");
              DialerServiceData.setDialerOption({
                ...dialerOption,
                ...{
                  call: true,
                  record: false,
                  keypad: false
                }
              });
            }
          } else {
            phoneNoErrorPrompt();
          }
        } else {
          phoneNoErrorPrompt();
        }
      } else if (+statusCode === 401) {
        toasterState.setToastMsg("UNAUTHORIZED Access", "fail");
      } else if (+statusCode === 426) {
        toasterState.setToastMsg("Please upgrade to continue", "fail");
      } else if (+statusCode === 427) {
        setUpgradeCredits(true);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } else {
      setUpgradeCredits(true);
    }
  };

  const endCall = () => {
    if (device) {
      device.disconnectAll();
      DialerServiceData.setDialerTokenObj(null);
      DialerServiceData.setDialerOption({ ...defaultDialerOption });
      setDialerOption({ ...defaultDialerOption });
      DialerServiceData.setDialerCallStatus("");
      DialerServiceData.setDialerIvrKeypad(false);
      DialerServiceData.setDialerCallLogs(true);
      setCallLog(dialerLogsPopupState.dialerLogSettings.callSid);
      if (activeConnection) {
        activeConnection.disconnect();
        if (!dialerLogsPopupState.dialerLogSettings.callSid) {
          closeDialerCard();
        }
        setActiveConnection(null);
      } else {
        setActiveConnection(null);
      }
      // callback to perform task operation
      if (globalCallState.popupValues.pageType === "tasks") {
        !dialerLogAfterCallEnd && performTaskCbk();
      }
    }
  };

  const handleMute = value => {
    if (device && activeConnection) {
      DialerServiceData.setDialerOption({ ...dialerOption, mute: value });
      value ? activeConnection.mute() : activeConnection.unmute();
    }
  };

  const handleCallRecording = async (type = "", conn = {}) => {
    if (
      activeConnection?.mediaStream?.CallSid ||
      activeConnection?.parameters?.CallSid ||
      conn?.mediaStream?.CallSid ||
      conn?.parameters?.CallSid
    ) {
      let callSid =
        activeConnection?.mediaStream?.CallSid ||
        activeConnection?.parameters?.CallSid ||
        conn?.mediaStream?.CallSid ||
        conn?.parameters?.CallSid;
      let url =
        type === "start"
          ? URLS.startRecording
          : type === "pause"
          ? URLS.pasueRecording
          : URLS.resumeRecording;
      try {
        const config = {
          url: url.replace("<<callSid>>", callSid),
          method: "POST"
        };
        let res = await makeApi(config);
        if (res?.data?.status === "success") {
          type === "start" && setRecordingActivated(true);
          DialerServiceData.setDialerOption({
            ...dialerOption,
            ...{
              call: true,
              keypad: false,
              record: ["start", "resume"].includes(type)
            }
          });
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
      } catch (e) {
        console.error(e, "Recording Error");
      }
    }
  };

  const getPhoneNo = (no = "") => {
    setPhoneNo(no?.trim() || "");
    setPhoneNoEdited(true);
    dialerLogsPopupState.setDialerLogSettings({
      ...dialerLogsPopupState.dialerLogSettings,
      ...{
        phoneNumber: no?.trim() || "",
        isContactHasNo: true
      }
    });
  };

  const dialerServiceMultiEvent = (name = "") => {
    switch (name) {
      case "call":
        call(false);
        break;
      case "endCall":
        endCall();
        break;
      case "mute":
        handleMute(true);
        break;
      case "unmute":
        handleMute(false);
        break;
      case "startRecording":
        DialerServiceData?.deviceStatus?.toLowerCase() === "open" &&
          handleCallRecording("start");
        break;
      case "pauseRecording":
        handleCallRecording("pause");
        break;
      case "resumeRecording":
        handleCallRecording("resume");
        break;
      default:
        break;
    }
  };

  // Twilio Setup
  const updateAllDevices = () => {
    updateDevices(device.audio.speakerDevices.get());
  };

  const updateDevices = selectedDevices => {
    device.audio.availableOutputDevices.forEach((dev, id) => {
      let isActive = selectedDevices.size === 0 && id === "default";
      selectedDevices.forEach(dev => {
        if (dev.deviceId === id) {
          isActive = true;
        }
      });
    });
  };

  const configureTwilioDevice = payload => {
    const decryptCapabilityToken = getDecryptCapabilityToken(
      payload?.capabilityToken
    );
    if (decryptCapabilityToken) {
      device.setup(decryptCapabilityToken, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true
      });

      device.audio.on("deviceChange", updateAllDevices.bind(device));

      device.on("disconnect", conn => {
        DialerServiceData.setDeviceStatus(conn?.status() || "");
        if (DialerServiceData.dialerCallStatus === "connected") {
          DialerServiceData.setDialerCallStatus("ended");
          setTimeout(() => {
            endCall();
          }, 1000);
        } else if (DialerServiceData.dialerCallStatus === "ended") {
          endCall();
        } else {
          DialerServiceData.setDialerCallStatus("disconnected");
          setTimeout(() => {
            endCall();
          }, 1000);
        }
      });

      device.on("connect", conn => {
        DialerServiceData.setDeviceStatus(conn?.status() || "");
        setActiveConnection(conn);
        setTimeout(() => {
          if (DialerServiceData?.deviceStatus?.toLowerCase() !== "closed") {
            userDetail.userFeatureFlag.recordingEnabled &&
              handleCallRecording("start", conn);
            DialerServiceData.setDialerCallStatus("connected");
            let callSid =
              conn?.mediaStream?.CallSid || conn?.parameters?.CallSid;
            dialerLogsPopupState.setDialerLogSettings({
              ...dialerLogsPopupState.dialerLogSettings,
              ...{
                callSid,
                contactName: Utils.getName(firstName, lastName, name)
              }
            });
          }
        }, 2500);
      });
    }
  };

  const getTokenObjAndInitTwilioConfig = () => {
    if (DialerServiceData.dialerTokenObj) {
      setTokenObj(DialerServiceData.dialerTokenObj);
      configureTwilioDevice(DialerServiceData.dialerTokenObj);
    }
    setTimeout(setLoader(false), 1000);
  };

  const checkPhonePresentOrEdited = () => {
    if (phone?.[0]) {
      return phoneNoEdited;
    }
    return true;
  };

  const getAndSaveRandomId = () => {
    const randomId = Utils.generateRandomNumber();
    setRandomId(randomId);
    return randomId;
  };

  const showCallLogsSliderCard = () => {
    return (
      !dialerOption.call &&
      dialerLogAfterCallEnd &&
      callLogs &&
      dialerLogsPopupState.dialerLogSettings.callSid
    );
  };

  useEffect(() => {
    setDialerCredits(userDetail.userFeatureFlag?.dialerCredits || 0);
  }, [userDetail.userFeatureFlag.dialerCredits]);

  useEffect(() => {
    if (DialerServiceData?.dialerDefaultNo !== "purchaseNumber") {
      setDefaultNo(DialerServiceData.dialerDefaultNo);
    }
  }, [DialerServiceData.dialerDefaultNo]);

  useEffect(() => {
    setDialerOption({ ...dialerOption, ...DialerServiceData?.dialerOption });
  }, [DialerServiceData.dialerOption]);

  useEffect(() => {
    getTokenObjAndInitTwilioConfig();
  }, [DialerServiceData.dialerTokenObj]);

  useEffect(() => {
    setCallLog(DialerServiceData.dialerCallLogs);
  }, [DialerServiceData.dialerCallLogs]);

  useEffect(() => {
    getTokenObjAndInitTwilioConfig();
    hideIntercomChat();
    return () => {
      dialerLogsPopupState.setDialerNumber("");
      setRecordingActivated(false);
    };
  }, []);

  useEffect(() => {
    setPhoneNo(getNumberList());
  }, [mobilePhone, phone, otherPhone, selectedNumber]);

  useEffect(() => {
    dialerLogsPopupState.setDialerNumber(phoneNo);
  }, [phoneNo]);

  return (
    <>
      <div
        className={`dialerServiceCardSection ${
          slideAnimation ? "slideInFromBottom" : "slideOutToBottom"
        }`}
        style={{ right: isContactCard ? "360px" : "0px" }}
      >
        <div className="dialerServiceCard">
          <SliderHeader
            isCloseBtnEnabled={closeBtnEnabled}
            title="Call"
            logo={false}
            closeDialerCard={closeDialerCard}
            isBackArrow={isBackArrow}
            backArrowCbk={backArrowCbk}
            toggleDropdown={toggleDropdown}
            setToggleDropDown={setToggleDropDown}
          />
          <div style={{ display: toggleDropdown ? "block" : "none" }}>
            {!dialerOption?.call && (
              <ContactInfo contact={data} showRoundedCircle={true} />
            )}
            {!callLogs && !dialerLogsPopupState?.showEditCallLogs && (
              <section className="dialerInformationArea">
                <DialerKeypad
                  contact={data}
                  cbkNoUpdate={getPhoneNo}
                  activeConnection={activeConnection}
                  isRecordingActivated={isRecordingActivated}
                  selectedNumber={selectedNumber}
                />
                {!loader && (
                  <DialerButton
                    cbk={dialerServiceMultiEvent}
                    activeConnection={activeConnection}
                    callSid={dialerLogsPopupState.dialerLogSettings.callSid}
                    isRecordingActivated={isRecordingActivated}
                    setRecordingActivated={setRecordingActivated}
                  />
                )}
                {loader && <DialerButtonLoader />}
                <DialerDefaultNumber calling={dialerOption?.call} />
                <DialerCredits
                  calling={dialerOption.call}
                  dialerCredits={dialerCredits?.toFixed(2) || 0}
                />
              </section>
            )}
            {callLogs &&
              !dialerLogAfterCallEnd &&
              !dialerLogsPopupState?.showEditCallLogs && (
                <>
                  <DialerKeypad
                    contact={data}
                    cbkNoUpdate={getPhoneNo}
                    activeConnection={activeConnection}
                    isRecordingActivated={isRecordingActivated}
                  />
                  {!loader && (
                    <DialerButton
                      cbk={dialerServiceMultiEvent}
                      activeConnection={activeConnection}
                      callSid={dialerLogsPopupState.dialerLogSettings.callSid}
                      isRecordingActivated={isRecordingActivated}
                      setRecordingActivated={setRecordingActivated}
                    />
                  )}
                  {loader && <DialerButtonLoader />}
                  <DialerDefaultNumber calling={dialerOption?.call} />
                  <DialerCredits
                    calling={dialerOption.call}
                    dialerCredits={dialerCredits?.toFixed(2) || 0}
                  />
                </>
              )}
            {(showCallLogsSliderCard() ||
              dialerLogsPopupState.showEditCallLogs) && (
              <DialerLogsAfterCallEnd
                contactName={Utils.getName(firstName, lastName, name)}
                phoneNumber={phoneNo}
                contactId={cId || contactId}
                closeDialer={closeDialerCard}
                isContactHasNo={checkPhonePresentOrEdited()}
                randomId={randomId}
                callSid={dialerLogsPopupState.dialerLogSettings.callSid}
                disableToggle={pageType === "calls"}
              />
            )}
            {showUpgradeCredits && (
              <DialerUpgradeCreditsPopup hide={hideUpgrade} />
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default DialerSliderCard;
export { DialerSliderCard };
