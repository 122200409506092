export const callSummaryHeader = [
  {
    name: "Name",
    className: "name",
    id: "name",
    width: 15,
    showHeader: true
  },
  {
    name: "Called by",
    className: "calledBy",
    id: "calledBy",
    width: 15,
    showHeader: true
  },
  {
    name: "Call Outcome",
    className: "callOutcome",
    id: "callOutcome",
    width: 12,
    showHeader: true
  },
  {
    name: "Call Notes",
    className: "callNotes",
    id: "callNotes",
    width: 20,
    showHeader: true
  },
  {
    name: "Date",
    className: "date",
    id: "date",
    width: 15,
    showHeader: true
  },
  {
    name: "Call Duration",
    className: "callDuration",
    id: "callDuration",
    width: 10,
    textAlign: "center",
    showHeader: true
  },
  {
    name: "Recording",
    className: "recording",
    id: "recording",
    width: 8,
    textAlign: "center",
    showHeader: true
  },
  {
    name: "Actions",
    className: "actions",
    id: "actions",
    width: 5,
    textAlign: "center",
    value: "NO_OUTCOME",
    showHeader: false
  }
];
