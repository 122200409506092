/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import userDetail from "Stores/userDetail";
import EmailInsightComponent from "Components/common/EmailInsights";
import CustomTooltip from "Components/common/CustomTooltip";
import { successPopupState } from "Components/common/SuccessPopup";
import ContactCardLoaderComponent from "./ContactCardLoaderComponent";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import InboxUtils from "Utils/InboxUtils";
import TokenUtils from "Utils/TokenUtils";
import { ContactUtils } from "Utils/ContactUtils";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { ContactCardFunction } from "./ContactCardFunction";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import DialerServiceData from "Stores/DialerServiceStore";
import { missingFieldsValidationPopupState } from "Components/ContactCard/MissingFieldsValidationPopup";
import contactStoreData from "Stores/contactData";
import { addSequenceInContactPopupState } from "Pages/Dashboard/contacts/addSeqInContactPopup";
import { toasterState } from "Components/common/toaster";
import { MP_EVENT, salesgearFieldMapping } from "Static/constant";
import { settingsStoreData } from "Stores/settingsData";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import { hideIntercomChat } from "Utils/Scripts/intercom";
import { editContactPopupState } from "Pages/Dashboard/contacts/EditContactPopup";
import { CONTACT_FIELD_VALUE } from "Model/model";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";
import globalCallState from "Stores/GlobalCallState";
import PaywallUtils from "Utils/PaywallUtils";
import AddRemoveTags from "./AddRemoveTags";
import { SequenceUtils } from "Utils/SequenceUtils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import UpdateContactStageDropdown from "./UpdateContactStageDropdown";
import AccountSocialLink from "./AccountSocialLink";
import PhoneNumberDropDown from "./PhoneNumberDropDown";
import { openCalendarPopup } from "Global/commonUtils";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";
import { ReactComponent as MakeACallIcon } from "Assets/svg/call.svg";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";
import { ReactComponent as SendEmailIcon } from "Assets/svg/email.svg";
import { ReactComponent as CalendarIcon } from "Assets/svg/calendar.svg";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
import { ReactComponent as AccountIcon } from "Global/SideBar/Icons/accounts.svg";
import SequenceList from "./SequenceList";
import DropdownMenu from "Components/common/DropDownMenu";
import { ReactComponent as OptedOutSvg } from "Assets/svg/optedOut.svg";
import { ReactComponent as ViewActivitySvg } from "Assets/svg/viewactivity.svg";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import CopyText from "./CopyText";
import PhoneNumberUtils from "./phoneNumberUtils";
import { phoneNumberStore } from "./phoneNumberStore";
import { priceRangeSliderPopupState } from "../popup/PriceRangeSliderPopup/main";

let CancelContactRequest = null;
let CancelContactTaskRequest = null;

const ContactCard = (props = {}) => {
  const history = useHistory();
  const { calendarEnabled = false } = userDetail?.userInfo || {};
  const {
    taskEnabled = false,
    dialerEnabled = false,
    calendarIntegrationEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const {
    contact = {},
    pageType = "",
    isReload = false,
    reloadTaskPage = false,
    contactCardLoader = true,
    isContactSlider = false,
    cardType = "",
    closeCbk = () => {},
    handleSuccessCallback = () => {},
    contactData = {},
    setContactData = () => {},
    setContactCardLoader = () => {},
    isReloadCbk = () => {},
    setTriggerTimeline = () => {},
    triggerContactCard = false,
    isFromPerformTask = false
  } = props || {};

  const defaultActionIconTp = {
    addToSequence: false,
    addToTask: false,
    makeACall: false
  };

  const [contactInfo, setContactInfo] = useState([]);
  const [contactLoader, setContactLoader] = useState(true);
  const [task, setTask] = useState({});
  const [taskLoader, setTaskLoader] = useState(false);
  const [actionIconTp, setActionIconTp] = useState(defaultActionIconTp);
  const [contactStages, setContactStages] = useState([]);
  const [activeContactStage, setActiveContactStage] = useState("");
  const [showPhoneNumberDropDown, setShowPhoneNumberDropDown] = useState(false);
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [showMoreAction, setShowMoreAction] = useState(false);

  const checkAndCancelContactRequest = () => {
    if (CancelContactRequest !== null) {
      CancelContactRequest("cancel");
      CancelContactRequest = null;
    }
  };

  const cancelContactCall = value => {
    CancelContactRequest = value;
  };

  const checkAndCancelContactTaskRequest = () => {
    if (CancelContactTaskRequest !== null) {
      CancelContactTaskRequest("cancel");
      CancelContactTaskRequest = null;
    }
  };

  const cancelContactTaskCall = value => {
    CancelContactTaskRequest = value;
  };

  const getContactInfo = async (loading = true) => {
    const { contactId = "", id = "" } = contact || {};
    let tempId = id || contactId;
    if (tempId) {
      try {
        const url = URLS.getContactDetail.replace("<<contactId>>", tempId);
        setContactLoader(loading);
        const config = {
          url
        };
        checkAndCancelContactRequest();
        let res = await makeApi(config, cancelContactCall);
        let { contact: contactResponse = {}, account = {} } = res?.data || {};
        if (contactResponse && Object.keys(contactResponse)?.length > 0) {
          let contactArr = [{ ...contactResponse, ...account }];
          globalCallState.setPopupValues({
            ...globalCallState?.popupValues,
            contactData: contactResponse
          });
          if (contactArr?.length > 0) {
            setContactInfo(contactArr);
            getPhoneNumberList(contactArr?.[0]);
            setActiveContactStage(contactArr?.[0]?.stageDisplayName || "");
            setTimeout(() => {
              expandContactSection();
            }, 0);
            setContactLoader(false);
            contactCardLoader && setContactCardLoader(false);
          }
        } else {
          setContactInfo([]);
        }
        setTriggerTimeline(false);
      } catch (error) {
        console.error("Getting contact card info error ", error);
      }
    }
  };

  const isDontContactDomain = (type = "") => {
    if (contactInfo?.length > 0) {
      return ContactUtils.isDontContactDomain(
        contactInfo?.[0]?.stageDisplayName?.toLowerCase(),
        type
      );
    }
    return true;
  };

  const getContactTaskInfo = async () => {
    const { contactId = "", id = "" } = contact || {};
    let tempId = id || contactId;
    if (tempId) {
      try {
        setTaskLoader(true);
        let targetUrl = URLS.getContactAllTask;
        if (targetUrl) {
          targetUrl = targetUrl.replace("<<contactId>>", tempId);
          const config = {
            url: targetUrl,
            method: "GET"
          };
          checkAndCancelContactTaskRequest();
          let res = await makeApi(config, cancelContactTaskCall);
          setTask(res?.data || {});
          setTaskLoader(false);
        }
      } catch (err) {
        console.error("Getting Contact All Task status info Error", err);
      }
    }
  };

  // Add to Sequence
  const addContactToSequence = async (data = {}) => {
    let settingsData = settingsStoreData.settingsData;
    const { title = "", payload = {}, contacts = [] } = data || "";
    const { sequenceId = "" } = payload;
    const contactsJsonArray = Utils.getBulkSequencePayload(contacts, true);
    const response = await Utils.scheduleSequenceForContacts(
      settingsData,
      contactsJsonArray,
      sequenceId
    );
    const {
      data: { responseCode = 500 } = {},
      response: { status = 500 } = {}
    } = response || {};
    if (+responseCode === 200) {
      Utils.mixpanelTrack(MP_EVENT.SQ_STARTED, {
        sequenceName: title,
        pageType
      });
      toasterState.setToastMsg("Sequence successfully scheduled", "success");
    } else if (+responseCode === 105) {
      SequenceUtils.showNoInboxconnectedPopup();
    } else if (+responseCode === 509) {
      Utils.showContactsUploadLimitReached();
    } else if (status === 426) {
      Utils.showUpgradePopup("contacts", true, true);
    }
    addBulkContactPopupState.setFileName("");
  };

  const validateContactBeforeAddingToSequence = async (data = {}) => {
    const { title = "", payload = {}, sequenceId = "" } = data || {};
    payload.sequenceId = sequenceId;
    ContactCardFunction.uploadingContact();
    let res = await contactStoreData.validateDailyLimitForContacts(payload);
    if (res && Object.keys(res)?.length) {
      const dataObject = { ...res, payload, title };
      const { contacts = [], fieldValidationResponse = {} } = res || "";
      const { missingFields = [], validCount = 0 } =
        fieldValidationResponse || {};
      if (missingFields?.length > 0 || !validCount) {
        fieldValidationResponse.contacts = contacts || {};
        missingFieldsValidationPopupState.setPopupValues({
          fieldResponse: fieldValidationResponse,
          data: dataObject,
          title: "Add Contact to sequence",
          skipAndAddContactCbk: () => {
            addContactToSequence(dataObject);
          },
          close: () => {
            missingFieldsValidationPopupState.resetPopup();
            contactStoreData.setValidateContactData("");
          }
        });
        missingFieldsValidationPopupState.setShowPopup(true);
        ContactCardFunction.resetUploadingFunc();
      } else {
        ContactCardFunction.resetUploadingFunc();
        addContactToSequence(dataObject);
      }
    }
  };

  const openSequenceList = (contactId = "", keyValue = "") => {
    if (isDontContactDomain(keyValue) && contactId) {
      const payload = {
        totalCount: 1,
        all: true,
        contactIds: [contactId]
      };
      const validateCbk = (data = {}, sequenceId = "") => {
        if (sequenceId) {
          validateContactBeforeAddingToSequence({
            ...data,
            sequenceId,
            payload
          });
        }
      };
      addSequenceInContactPopupState.setCallback(validateCbk);
      addSequenceInContactPopupState.setShowPopup(true);
    }
  };
  // End Add to Sequence

  // Task Creation
  const startUploadingContactToTask = () => {
    addStagesPopupState.setShowPopup(false);
    addStagesPopupState.setShowStepEmailEditorPopup(false);
    ContactCardFunction.uploadingContact("Task");
  };

  const openCreateTaskPopup = async (contactId = "", keyValue = "") => {
    if (isDontContactDomain(keyValue)) {
      if (taskEnabled) {
        if (
          contactId &&
          (await checkTeamSubscriptionActive()) &&
          !fileAttachmentStore?.taskFileUploadingLoader
        ) {
          const createTaskCallback = (taskData = {}, loaderCbk = () => {}) => {
            startUploadingContactToTask();
            AddContactToTask(taskData, loaderCbk, contactId);
          };
          addStagesPopupState.setPopupValues({
            pageSource: "Contacts",
            isTaskCreation: true,
            stageType: "manualEmail",
            btnText: "Next",
            contactIds: [contactId],
            allContactSelected: false,
            createCbk: createTaskCallback
          });
          addStagesPopupState.setShowPopup(true);
        }
      } else if (!taskEnabled) {
        upgradeToHigherPlan("taskPage");
      }
    }
  };

  const upgradeToHigherPlan = type => {
    PaywallUtils.upgradeUtilsFunction(type, false, planName, inFreePlan);
  };

  const handleTaskPopupSuccess = (tasksCreated = 0) => {
    successPopupState.setPopupValues({
      type: "task",
      text: `Task created successfully`
    });
    successPopupState.setPopup(true);
  };

  const handleTaskPopupSuccessFailure = (
    tasksCreated = 0,
    missingFields = []
  ) => {
    successPopupState.setPopupValues({
      type: "task",
      text: `Task successfully created for <br /> ${tasksCreated} contact${
        tasksCreated > 1 ? "s" : ""
      } <br/><br/>
      Please add the following mandatory ${
        missingFields?.length > 1 ? "fields" : "field"
      } missing for contact(s)<br/><br/> ${TokenUtils.getMissingTokenList(
        missingFields
      )}`
    });
    successPopupState.setPopup(true);
  };

  const AddContactToTask = async (
    taskData = {},
    loaderCbk = () => {},
    contactId = ""
  ) => {
    if (taskData && Object.keys(taskData)?.length) {
      try {
        const config = {
          url: URLS.bulkContactsTask,
          method: "POST",
          data: ContactCardFunction.constructBulkTaskPayload({
            ...taskData,
            contactId
          })
        };
        const res = await makeApi(config);
        const {
          tasksCreated = 0,
          responseCode = 0,
          missingFields = []
        } = res?.data || {};
        ContactCardFunction.resetUploadingFunc();
        if (!missingFields && tasksCreated) {
          loaderCbk();
          Utils.mixpanelTrack(MXP_EVENT.TASK_CREATED, {
            pageType,
            type: "Contact card"
          });
          reloadTaskPage && handleSuccessCallback();
          setTaskLoader(true);
          getContactTaskInfo();
          handleTaskPopupSuccess(tasksCreated);
          return;
        } else if (responseCode === 406) {
          if (tasksCreated > 0) {
            loaderCbk();
            handleTaskPopupSuccessFailure(tasksCreated, missingFields);
            return;
          } else {
            loaderCbk(true);
            missingFieldsValidationPopupState.setPopupValues({
              fieldResponse: {
                missingFields,
                uploadedCount: 1,
                validCount: tasksCreated
              },
              title: "Add Contact to Task",
              close: () => {
                missingFieldsValidationPopupState.resetPopup();
                addStagesPopupState.setResetValue();
              }
            });
            missingFieldsValidationPopupState.setShowPopup(true);
          }
        } else {
          loaderCbk();
          toasterState.setToastMsg("Task creation failed", "fail");
        }
      } catch (e) {
        toasterState.setToastMsg("Task creation failed", "fail");
        loaderCbk();
      }
    }
  };
  // End Task Creation

  // SEND ONE Off EMAIL
  const showSendOneOffEmailEditor = async (contactId = "", keyValue = "") => {
    if (isDontContactDomain(keyValue)) {
      if (
        Utils.isAccountConnected() &&
        !fileAttachmentStore?.taskFileUploadingLoader
      ) {
        let fromEmail = InboxUtils.getPrimaryInboxEmail();
        if (fromEmail) {
          let bccEmail = [];
          let toEmail = contactInfo?.[0]?.email || "";
          if (
            settingsStoreData?.settingsData?.insertBcc &&
            settingsStoreData?.settingsData?.bccEmail?.length > 0
          ) {
            bccEmail.push({ email: settingsStoreData?.settingsData?.bccEmail });
          }
          let contentDom = Utils.insertSignature(fromEmail, true);
          let validatedEmailObject = {};
          let emailStatusResponse = await Utils.validateEmailAddressStatus([
            ...Utils.getEmailList({ bcc: bccEmail }),
            toEmail
          ]);
          if (emailStatusResponse && Object.keys(emailStatusResponse)?.length) {
            validatedEmailObject = Utils.addEmailStatus(
              {
                bcc: bccEmail || [],
                to: [{ email: toEmail }]
              },
              emailStatusResponse
            );
          }
          let emailDataObj = {
            ...emailPreviewContentPopupState.popupValues.emailData,
            from: fromEmail,
            ...validatedEmailObject,
            content: Utils.addEmptySpaceAboveThreadEmail(contentDom)
          };
          let localStateObj = {
            ...emailPreviewContentPopupState.editorInputValue.localState,
            showBccInputAddress: bccEmail?.length > 0,
            editorInsertCounter: 0
          };
          let tempObj = {
            emailType: "ONE_OFF_EMAIL",
            pageType,
            ...emailPreviewContentPopupState.popupValues,
            ...{ emailData: emailDataObj },
            ...{ localState: localStateObj }
          };
          emailPreviewContentPopupState.setEditorInputValue(tempObj);
          emailPreviewContentPopupState.setshowEditorComposePopup(true);
          emailPreviewContentPopupState.setShowPopup(true);
        }
      } else {
        Utils.showAddAccountPopup(
          { history },
          "Please connect your email account to send emails",
          () => emailPreviewContentPopupState.setResetPopup()
        );
      }
    }
  };
  // End ONE Off EMAIL

  // Make a Call
  const makeACallAction = async (
    contactId = "",
    keyValue = "",
    selectedNumber = ""
  ) => {
    if (isDontContactDomain(keyValue)) {
      if (await checkTeamSubscriptionActive()) {
        if (dialerEnabled !== "") {
          if (dialerEnabled) {
            if (DialerServiceData?.dialerDefaultNo !== "purchaseNumber") {
              await DialerServiceData.getDailerToken();
              DialerServiceData.setDialerCallLogs(false);
              globalCallState.setPopupValues({
                ...globalCallState.popupValues,
                ...{
                  pageType,
                  isFromContactSection: true,
                  isContactCard: pageType?.toLowerCase() !== "performtask",
                  contactData: contactInfo?.[0] || {},
                  selectedNumber: selectedNumber
                }
              });
              globalCallState.setShowCallSlider(true);
              Utils.mixpanelTrack(MXP_EVENT.MAKE_CALL_CLICKED, {
                pageType,
                type: "Contact Card"
              });
            } else {
              Utils.showUpgradeSettingsPopup("dialerPurchase", true, true);
            }
          } else {
            upgradeToHigherPlan("dialer");
          }
        } else {
          toasterState.setToastMsg("Please wait...", "info");
        }
      }
    }
  };

  // Booking Meeting
  const openCalendarBookMeetingPopup = async (
    contactId = "",
    keyValue = ""
  ) => {
    if (isDontContactDomain(keyValue)) {
      openCalendarPopup(history, "", contactInfo);
    }
  };
  // End

  const actionList = [
    {
      name: "Book Meeting",
      id: "calendarBookMeeting",
      method: openCalendarBookMeetingPopup,
      icon: <CalendarIcon />,
      keyValue: "calendar",
      show: calendarEnabled && calendarIntegrationEnabled
    },
    {
      name: "Send an Email",
      id: "sendOneOffEmail",
      method: showSendOneOffEmailEditor,
      icon: <SendEmailIcon />,
      keyValue: "email",
      show: true
    },
    {
      name: "Add to Sequence",
      id: "addToSequence",
      method: openSequenceList,
      icon: <SequenceIcon />,
      keyValue: "sequence",
      show: true
    },
    {
      name: "Create a Task",
      id: "addToTask",
      method: openCreateTaskPopup,
      icon: <TaskIcon />,
      keyValue: "task",
      show: true
    },
    {
      name: "Make a Call",
      id: "makeACall",
      method: makeACallAction,
      icon: <MakeACallIcon />,
      keyValue: "call",
      show: true
    },
    {
      id: "moreActions",
      icon: <i className="material-icons">more_vert</i>,
      keyValue: "moreActions",
      show: true
    }
  ];

  const handleTooltip = (id = "", value = "") => {
    if (id && ["moreactions"].includes(id?.toLowerCase())) {
      setShowMoreAction(value);
    } else {
      if (
        id &&
        phoneNumberList?.length > 0 &&
        id?.toLowerCase() === "makeacall"
      ) {
        setShowPhoneNumberDropDown(value);
      } else {
        const tempObj = { ...actionIconTp };
        tempObj[id] = value;
        setActionIconTp(tempObj);
      }
    }
  };

  const getContact = () => {
    if (
      contact &&
      Object.keys(contact)?.length > 0 &&
      (contact?.id || contact?.contactId)
    ) {
      getContactInfo();
      getContactTaskInfo();
    }
  };

  const viewActivity = () => {
    history.push(
      `/contacts/${
        contactInfo?.[0]?.id || contactInfo?.[0]?.contactId
      }/activity`,
      {
        contact: contactInfo?.[0] || {}
      }
    );
  };

  const handleUpdateContactSuccess = (contact = {}, updatedTags) => {
    let contactList = contactData?.contacts || [];
    getContactInfo(false);
    const updatedContactList = contactList.map(item => {
      if (item?.email === contact?.email) {
        modifyTableDataFromResponse(item, contact, updatedTags);
      }
      return item;
    });
    contactData.contacts = updatedContactList;
    setContactData(contactData);
  };

  const modifyTableDataFromResponse = (
    tableData,
    responseData,
    updatedTags
  ) => {
    tableData.name = responseData.firstName;
    tableData.firstName = responseData.firstName;
    tableData.lastName = responseData.lastName;
    tableData.phone = responseData.phone;
    tableData.linkedinId = responseData.linkedinId;
    tableData.jobTitle = responseData.jobTitle;
    tableData.company = responseData.company;
    tableData.mobilePhone = responseData.mobilePhone;
    tableData.otherPhone = responseData.otherPhone;
    tableData.industry = responseData.industry;
    tableData.street = responseData.street;
    tableData.city = responseData.city;
    tableData.state = responseData.state;
    tableData.country = responseData.country;
    tableData.zipCode = responseData.zipCode;
    tableData.website = responseData.website;
    tableData.facebook = responseData.facebook;
    tableData.twitter = responseData.twitter;
    tableData.contactNotes = responseData.contactNotes;
    tableData.customFields = responseData.customFields;
    tableData.contactTags = updatedTags;
    return tableData;
  };

  const redirectToSequence = (seqId = "", folderId = "", sharedType = "") => {
    const sequencePath = `/sequences/${
      sharedType === "SHARED" ? "shared" : folderId || "all"
    }/${seqId}`;
    history.push(sequencePath);
    closeCbk();
  };

  const handleEditClick = (item = {}) => {
    let updatedItem = extractPhoneNumbersFromContact(item);
    editContactPopupState.setContact(updatedItem);
    editContactPopupState.setShowPopup(true);
    editContactPopupState.setCallback(handleUpdateContactSuccess);
  };

  const collapseBtn = e => {
    let expandItemList = document.querySelectorAll(
      ".wrapContactActivity .collapsible.active"
    );
    if (expandItemList?.length) {
      expandItemList.forEach(item => {
        if (item.id !== e.currentTarget.id) {
          item.classList.toggle("active");
          let element = item.nextElementSibling;
          element.style.maxHeight = null;
        }
      });
    }
    e.target.classList.toggle("active");
    let content = e.target.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = "fit-content";
    }
  };

  const extractPhoneNumbersFromContact = (obj = {}) => {
    if (obj?.phone?.length) {
      obj.phone = obj.phone[0];
    }
    if (obj?.mobilePhone?.length) {
      obj.mobilePhone = obj.mobilePhone[0];
    }
    if (obj?.otherPhone?.length) {
      obj.otherPhone = obj.otherPhone[0];
    }
    return obj;
  };

  const labelClassName = {
    email: "contactEmail",
    phone: "contactPhone",
    mobilePhone: "contactPhone",
    otherPhone: "contactPhone",
    owner: "contactOwner",
    stage: "contactStatus",
    task: "contactTask",
    taggedSequences: "contactSequenceList",
    insights: "insightData",
    contactTags: "contactTagList",
    accountEmailInsights: "insightData",
    companyPhone: "contactPhone"
  };

  const gateTaskData = () => {
    return !taskLoader ? (
      <div className="contactTask">
        <span className="completed">
          {task?.completed?.total || 0}{" "}
          <span className="smallText">Completed</span>
        </span>
        <span className="completed">
          {task?.total?.total - task?.completed?.total || 0}{" "}
          <span className="smallText">Due</span>
        </span>
      </div>
    ) : (
      <span className="linear-background" />
    );
  };

  const getNumberListData = (id = "", list = [], contactData = {}) => {
    return (
      list?.length && (
        <>
          {list.map((val, idx) => {
            let number = val?.number || val || "";
            if (number) {
              return (
                <div className="chip" title={number} key={`${id}Chip${idx}`}>
                  <span className="phoneText">{number}</span>
                  {!contactData?.hasShowMobilePhone && (
                    <CopyText value={number} />
                  )}
                  {contactData?.hasShowMobilePhone && (
                    <span
                      className="showBtn"
                      onClick={() =>
                        priceRangeSliderPopupState.setShowPopup(true)
                      }
                    >
                      Show
                    </span>
                  )}
                </div>
              );
            }
          })}
        </>
      )
    );
  };

  const getCompanyLocation = (address = {}) => {
    const { city = "", state = "", country } = address || {};
    if (city || state || country) {
      return `${city}${city && (state || country) ? ", " : ""}${state}${
        state && country ? ", " : ""
      }${country}`;
    } else {
      return "--";
    }
  };

  const redirectToAccount = () => {
    const { accountId = "", account = {} } = contactInfo?.[0] || {};
    let aId = accountId || account?.id || "";
    if (aId) {
      history.push(`/accounts/${aId}/overview`);
    }
  };

  const getRenderValue = (obj = {}, childId = "", parentId = "") => {
    return parentId === "contact" && childId === "companyPhone"
      ? obj?.companyDetails?.phone
      : parentId === "customFields" ||
        (parentId === "account" &&
          !["accountOverallInsights", "accountEmailInsights"].includes(
            childId
          )) ||
        (parentId === "companyDetails" && childId !== "company")
      ? obj?.[parentId]?.[childId]
      : obj[childId] || "";
  };

  const handleReloadCallback = (keyType = "", obj = {}) => {
    getContact();
    if (cardType) {
      switch (cardType?.toLowerCase()) {
        case "inbox":
        case "sequence_contact":
        case "timelin;e":
        case "tasks":
        case "account_tasks":
        case "perform_task":
          isReloadCbk(keyType, obj);
          break;
        case "contacts":
          ["OPTED_OUT"].includes(keyType?.toUpperCase()) && isReloadCbk();
          break;
        default:
          break;
      }
    }
  };

  // OPT OUT
  const optOutContactRequest = async (contactId = "", name = "") => {
    if (contactId) {
      if (await checkTeamSubscriptionActive()) {
        const config = {
          url: URLS.optOutContact,
          method: "POST",
          data: { contactId }
        };
        const response = await makeApi(config);
        const respStatus = response?.status || response?.response?.status || "";
        confirmationPopupState.setShowPopup(false);
        if (respStatus === 200) {
          handleReloadCallback("OPTED_OUT");
          toasterState.setToastMsg(`${name} was opted out`, "success");
        } else if (respStatus === 403) {
          toasterState.setToastMsg(
            "You don't have access to this contact",
            "failure"
          );
        } else {
          Utils.showApiFailureResponse(response);
        }
      }
    } else {
      confirmationPopupState?.showPopup &&
        confirmationPopupState.setShowPopup(false);
    }
  };

  const updateContactAsOptOut = (itemValue = {}) => {
    if (itemValue?.id) {
      const {
        firstName = "",
        lastName = "",
        name = "",
        email = "",
        id = ""
      } = itemValue || {};
      let contactName = ContactCardFunction.getName({
        firstName,
        lastName,
        name,
        email
      });
      confirmationPopupState.setPopupValues({
        content: `<span style="color: #343A40;">Are you sure you want to Opt out${
          contactName && contactName !== "--" ? ` <b>${contactName}` : ""
        }</b></span>? <br/><span style='color: #8D8D8D;font-size: 14px;'>The contact will be opted out from all sequences they are currently in.</span>`,
        actionBtnText: "Yes",
        cancelBtnText: "Cancel",
        cancelCbk: () => confirmationPopupState.setShowPopup(false),
        callback: () => {
          optOutContactRequest(id, contactName);
        },
        loadingMsg: "Opting out. Please wait..."
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  // END

  const moreOptions = [
    {
      icon: {
        ele: <ViewActivitySvg />
      },
      value: "View Activity",
      key: "viewContactActivity",
      method: () => {
        setShowMoreAction(false);
        viewActivity();
      }
    },
    {
      icon: {
        ele: '<span style="font-size: 14px; color: #3b3b3b;"  class="material-icons-outlined">edit</span>'
      },
      value: "Edit Contact",
      key: "EditContactInfoCard",
      method: (itemValue = {}) => {
        setShowMoreAction(false);
        handleEditClick(itemValue);
      }
    }
  ];

  const optedOutObj = {
    icon: {
      ele: <OptedOutSvg />
    },
    value: "Opt Out",
    key: "optOutContact",
    method: (itemValue = {}) => {
      setShowMoreAction(false);
      updateContactAsOptOut(itemValue);
    }
  };

  const getDomValue = (
    childId = "",
    obj = "",
    parentId = "",
    contactName = ""
  ) => {
    let value = getRenderValue(obj, childId, parentId) || "";
    if (childId === "contactTags") {
      return (
        <AddRemoveTags
          tagsArr={value}
          email={contactInfo?.[0]?.email}
          contactName={contactName}
          reloadCbk={validateContactAndTimelineCbk}
        />
      );
    } else if (
      ["taggedSequences"].includes(childId) &&
      typeof value === "object"
    ) {
      return (
        <SequenceList
          contact={contact}
          list={value}
          redirect={redirectToSequence}
          contactId={contact?.id || contact?.contactId || ""}
          reloadCbk={handleReloadCallback}
        />
      );
    } else if (["mobilePhone"].includes(childId)) {
      if (typeof value === "object" && value[0]) {
        return getNumberListData(childId, value, contactInfo[0]);
      } else {
        return phoneNumberStore.phoneNumberLoading
          ? PhoneNumberUtils.getPhoneNumberLoading()
          : PhoneNumberUtils.getPhoneNumber(
              childId,
              contact?.id || contact?.contactId || "",
              contactInfo,
              setContactInfo,
              isFromPerformTask ? getContactInfo : () => {}
            );
      }
    } else if (
      ["phone", "otherPhone", "companyPhone"].includes(childId) &&
      typeof value === "object"
    ) {
      return getNumberListData(childId, value) || "--";
    } else if (parentId === "companyDetails" && childId === "address") {
      return getCompanyLocation(value?.[0]) || "--";
    } else if (childId === "stage") {
      return value?.toLowerCase()?.replace("_", " ") || "--";
    } else if (childId === "owner") {
      return ContactCardFunction.getOwnerName(value) || "--";
    } else if (["accountEmailInsights", "insights"].includes(childId)) {
      return (
        (value && Object.keys(value)?.length && (
          <EmailInsightComponent emailInsight={value} />
        )) ||
        "--"
      );
    } else if (["task", "accountOverallInsights"].includes(childId)) {
      return gateTaskData();
    } else if (value && ~value?.indexOf(".")) {
      let url =
        ~value.indexOf("@") && Utils.emailValidate(value?.trim()?.toLowerCase())
          ? `mailto:${value}`
          : ~value.indexOf("http")
          ? value
          : `http://${value}`;
      return (
        <div className="emailCopyWrapper">
          <a
            href={url}
            className="linkValue"
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
          <CopyText value={value} />
        </div>
      );
    } else if (childId === "name" && parentId === "account") {
      let domain =
        obj?.[parentId]?.["domain"] || obj?.["email"]?.split("@")?.[1] || "";
      return (
        <div className="accountWrapper">
          <div className="accountRedirect" onClick={redirectToAccount}>
            <AccountIcon />
            <span className="accountText">
              {value || domain?.split(".")?.[0] || domain || "--"}
            </span>
          </div>
          <AccountSocialLink
            {...(obj?.[parentId] || {})}
            websiteDomain={domain}
            iconSize="small"
          />
        </div>
      );
    }
    return value || "--";
  };

  const hasInformation = (key = "", obj = "") => {
    return (
      key !== "customFields" ||
      (key === "customFields" && obj && Object.keys(obj).length > 0)
    );
  };

  const hasCustomFields = (key = "") => {
    return key !== "id" && ~key.indexOf("customField");
  };

  const getCustomFieldLabel = (keyLabel = "") => {
    return (
      (keyLabel && settingsStoreData?.customFields?.[keyLabel]) ||
      salesgearFieldMapping[keyLabel?.toLowerCase()] ||
      "--"
    );
  };

  const expandContactSection = () => {
    let expandItemList = document.querySelectorAll(
      ".wrapContactActivity .collapsible.active"
    );
    if (expandItemList?.length < 1) {
      let expandItem = document.querySelector(
        ".wrapContactActivity .collapsible"
      );

      if (expandItem) {
        expandItem.classList.toggle("active");
        let content = expandItem.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = "fit-content";
        }
      }
    }
  };

  const closeContactCard = () => {
    closeCbk();
    if (globalCallState.popupValues?.isContactCard) {
      globalCallState.setPopupValues({
        ...globalCallState.popupValues,
        isContactCard: false
      });
    }
  };

  const getContactStages = async () => {
    const config = {
      url: URLS.getContactStage,
      method: "GET"
    };
    let response = await makeApi(config);
    if (+response?.status === 200 && response?.data?.length > 0) {
      setContactStages(response?.data);
    } else {
      setContactStages([]);
    }
  };

  const reloadContactData = () => {
    if (
      ((contact?.id || contact?.contactId) && isContactSlider) ||
      triggerContactCard
    ) {
      getContact();
    }
  };

  const validateContactAndTimelineCbk = () => {
    isReload && isReloadCbk();
    // To reload contact timeline in task page on any update made in contact card we use state based setTriggerTimeline
    if (["performtask"].includes(pageType?.toLowerCase())) {
      setTriggerTimeline(true);
    }
  };

  const getPhoneNumberList = (obj = {}) => {
    let tempPhoneNumList = [];
    if (obj?.phone && obj?.phone?.length > 0) {
      tempPhoneNumList = obj?.phone.map(item => {
        return { type: "Phone", number: item };
      });
    }
    if (obj?.mobilePhone && obj?.mobilePhone?.length > 0) {
      let tempMobilePhoneNumList = obj?.mobilePhone.map(item => {
        return { type: "Mobile", number: item };
      });
      tempPhoneNumList = [...tempPhoneNumList, ...tempMobilePhoneNumList];
    }
    if (obj?.otherPhone && obj?.otherPhone?.length > 0) {
      let tempOtherPhoneNumList = obj?.otherPhone.map(item => {
        return { type: "Other", number: item };
      });
      tempPhoneNumList = [...tempPhoneNumList, ...tempOtherPhoneNumList];
    }
    if (obj?.companyDetails && obj?.companyDetails?.phone?.length > 0) {
      let tempObj = obj?.companyDetails?.phone;
      let compPhnArr = tempObj.map(item => {
        return { type: "Company", number: item?.number };
      });
      tempPhoneNumList = [...tempPhoneNumList, ...compPhnArr];
    }
    setPhoneNumberList(tempPhoneNumList);
  };

  const titleLabel = {
    accounts: "Accounts Details",
    customFields: "Custom Fields",
    companyDetails: "Company Information"
  };

  useEffect(() => {
    globalCallState.setPopupValues({
      ...globalCallState.popupValues,
      isContactCard: true
    });
    getContactStages();
    getContact();
    hideIntercomChat();
    return () => {
      setActionIconTp(defaultActionIconTp);
      closeCbk();
      contactStoreData.setBreadCrumbValue("");
      globalCallState.resetPopupValues();
    };
  }, []);

  useEffect(() => {
    if (contactCardLoader) {
      setContactLoader(true);
    }
  }, [contactCardLoader]);

  useEffect(() => {
    reloadContactData();
  }, [contact?.id, contact?.contactId, triggerContactCard]);

  return (
    <section
      className={`contactCard ${contactLoader ? "contactCardLoader" : ""}`}
    >
      {isContactSlider && (
        <i
          className="material-icons closeContactSlider"
          onClick={closeContactCard}
        >
          close
        </i>
      )}
      {!contactLoader && contactInfo?.length > 0 ? (
        contactInfo.map((itemValue, index) => {
          const {
            id: contactId,
            company,
            jobTitle,
            firstName,
            lastName,
            name,
            email,
            linkedinId,
            website,
            firstContacted,
            lastContacted,
            account = {},
            accountId = "",
            stageDisplayName = ""
          } = itemValue || {};

          const userData = { firstName, lastName, name, email };
          let contactName = ContactCardFunction.getName(userData);
          !isContactSlider && contactStoreData.setBreadCrumbValue(contactName);
          return (
            <div
              className="contactInfoCard"
              key={`contactInfoCard${index + 1}`}
            >
              <div className="contactDetails">
                <div className="flexCenter justifyCenter">
                  <h4 className="contactName">{contactName}</h4>
                  <div className="contactSocialIconLink">
                    <span
                      className={`websiteIcon material-icons-outlined ${
                        website ? "" : "disabled"
                      }`}
                      onClick={() =>
                        (website &&
                          Utils.windowRedirect(
                            Utils.removeHttpFromString(website),
                            "_blank",
                            true,
                            true
                          )) ||
                        {}
                      }
                    >
                      link
                    </span>
                    <span
                      className={`linkedinIcon ${linkedinId ? "" : "disabled"}`}
                      onClick={() =>
                        (linkedinId &&
                          Utils.windowRedirect(
                            Utils.removeHttpFromString(linkedinId),
                            "_blank",
                            true,
                            true
                          )) ||
                        {}
                      }
                    >
                      <LinkedinIcon />
                    </span>
                  </div>
                </div>
                <div className="contactJobCompany">
                  {jobTitle || "--"} @ {company || "--"}
                </div>
                <div className="contactContactedInfo">
                  <div className="first contactDate">
                    <p>First contacted</p>
                    <h6>{Utils.formatDate(firstContacted) || "--"}</h6>
                  </div>
                  <div className="last contactDate">
                    <p>Last contacted</p>
                    <h6>{Utils.formatDate(lastContacted) || "--"}</h6>
                  </div>
                </div>
                <div className="actionIcon">
                  {actionList.map((item, indexVal) => {
                    const {
                      icon = <></>,
                      name = "",
                      id = "",
                      keyValue = "",
                      show = false,
                      method = () => {}
                    } = item || {};
                    return (
                      show && (
                        <div
                          onClick={() => method(contactId, keyValue)}
                          key={id + indexVal}
                          className={`actionBtnIcon ${id}`}
                          onMouseOver={() => handleTooltip(id, true)}
                          onMouseLeave={() => handleTooltip(id, false)}
                        >
                          {icon}
                          {name && actionIconTp[id] && (
                            <CustomTooltip text={name} />
                          )}
                          {showPhoneNumberDropDown &&
                            id?.toLowerCase() === "makeacall" && (
                              <PhoneNumberDropDown
                                showPhoneNumberDropDown={
                                  showPhoneNumberDropDown
                                }
                                setShowPhoneNumberDropDown={
                                  setShowPhoneNumberDropDown
                                }
                                phoneNumberList={phoneNumberList}
                                makeACallAction={makeACallAction}
                              />
                            )}
                          {id?.toLowerCase() === "moreactions" &&
                            showMoreAction && (
                              <DropdownMenu
                                options={
                                  stageDisplayName?.toLowerCase() !==
                                  "opted out"
                                    ? [...moreOptions, optedOutObj]
                                    : moreOptions
                                }
                                referenceId={itemValue}
                              />
                            )}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
              <div className="contactActivityInfo">
                <div className={`wrapContactActivity ${pageType || ""}`}>
                  {Object.keys(CONTACT_FIELD_VALUE).map((keyVal, pIdx) => {
                    let value = CONTACT_FIELD_VALUE[keyVal];
                    if (
                      (keyVal === "account" &&
                        account &&
                        Object.keys(account)?.length &&
                        (account?.id || accountId)) ||
                      keyVal !== "account"
                    ) {
                      return (
                        hasInformation(keyVal, itemValue[keyVal]) && (
                          <div key={`contactDivison${pIdx}`}>
                            <div
                              className="collapsible"
                              id={`${keyVal}Header`}
                              onClick={e => collapseBtn(e)}
                            >
                              {titleLabel[keyVal] ||
                                `${Utils.firstLetterCapitialize(
                                  keyVal
                                )} Information`}
                            </div>
                            <div className="collapsiveContentBody">
                              {Object.keys(
                                keyVal === "customFields"
                                  ? itemValue[keyVal]
                                  : value
                              ).map((key, cIdx) => {
                                if (
                                  keyVal !== "customFields" ||
                                  hasCustomFields(key)
                                ) {
                                  return (
                                    <div className={`gridCol`} key={key + cIdx}>
                                      <span
                                        className={`icon ${
                                          key === "contactTags"
                                            ? "iconStart"
                                            : ""
                                        }`}
                                      >
                                        {keyVal === "customFields"
                                          ? getCustomFieldLabel(key)
                                          : value[key]}
                                      </span>
                                      <span
                                        className={`${
                                          key !== "stageDisplayName"
                                            ? `valueColumn ${
                                                labelClassName[key] || ""
                                              }`
                                            : "updateContactStage"
                                        }`}
                                      >
                                        {key === "stageDisplayName" ? (
                                          <UpdateContactStageDropdown
                                            stage={activeContactStage}
                                            setStage={setActiveContactStage}
                                            contactStages={contactStages}
                                            entityId={
                                              contactInfo?.[0]?.id ||
                                              contact?.id
                                            }
                                            contactName={contactName}
                                            reloadCbk={
                                              validateContactAndTimelineCbk
                                            }
                                          />
                                        ) : (
                                          getDomValue(
                                            key,
                                            itemValue,
                                            keyVal,
                                            contactName
                                          )
                                        )}
                                      </span>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <ContactCardLoaderComponent />
      )}
    </section>
  );
};

export default observer(ContactCard);
export { ContactCard };
