import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DateUtils from "Utils/DateUtils";
import makeApi, { URLS } from "Utils/apiURL";
import Button from "Components/common/Button";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import CustomTooltip from "Components/common/CustomTooltip";
import { salesforceConnectedData } from "../salesforce/SalesforceConnectedData";
import { syncConfirmationPopupState } from "../SyncConfirmationPopup";
import { syncLogsHistoryPopupState } from "../syncLogsHistoryPopup/state";

const ImportNow = observer(props => {
  const {
    lastSynced = "",
    syncStartTime = "",
    syncNow = false,
    listDetails = {},
    type = "CONTACTS",
    crmName = "",
    hasConditions = false,
    resumeDate = "",
    id: crmSettingId = ""
  } = props || {};

  const {
    sourceName = "",
    sourceId = "",
    listChanged = false
  } = listDetails || {};

  const { apiCallsUsed = 0 } = salesforceConnectedData?.salesForceData || {};
  const [showTooltip, setShowTooltip] = useState(false);

  const showResumeMsg =
    salesforceConnectedData?.apiLimitPerDay <= apiCallsUsed && resumeDate;
  const isSalesforce =
    crmName?.toLowerCase() === "salesforce" && (hasConditions || showResumeMsg);

  const getLastSyncedDate = () => {
    if (lastSynced) {
      const { date: lastSyncedDate = "", time: lastSyncedTime = "" } =
        DateUtils.getFormattedDateAndTimeForCrm(lastSynced) || {};
      return `${lastSyncedDate}, ${lastSyncedTime}`;
    }
    return null;
  };

  const getSyncStartTime = () => {
    if (syncStartTime) {
      const { date: lastSyncedDate = "", time: lastSyncedTime = "" } =
        DateUtils.getFormattedDateAndTimeForCrm(syncStartTime) || {};
      return `${lastSyncedDate}, ${lastSyncedTime}`;
    }
    return "";
  };

  const saveChanges = () => {
    syncConfirmationPopupState.setShowPopup(false);
    confirmationPopupState.setPopupValues({
      content: listChanged
        ? "Please save your changes before trying to sync contacts"
        : `Please select a ${crmName} list view from which you would like to import contacts.`,
      actionBtnText: "Ok",
      needCancelBtn: false,
      callback: () => {
        confirmationPopupState.setShowPopup(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  const importCbk = async () => {
    if ((sourceId && !listChanged) || isSalesforce) {
      syncConfirmationPopupState.setLoading(true);
      const config = {
        url:
          crmName?.toLowerCase() === "salesforce"
            ? `${URLS.syncSfNow}/${type}`
            : crmName === "Zoho"
            ? `${URLS.syncNow}/${type}`
            : crmName === "Pipedrive"
            ? `${URLS.syncPipedriveNow}`
            : `${URLS.syncHubspotNow}`,
        method: "POST"
      };
      const response = await makeApi(config);
      if (response?.data) {
        const { responseCode = "" } = response?.data || {};
        syncConfirmationPopupState.setLoading(false);
        syncConfirmationPopupState.setShowPopup(false);
        if (+responseCode === 200) {
          toasterState.setToastMsg(
            "Contact sync has been initiated successfully.",
            "success"
          );
          syncConfirmationPopupState.setSyncNowDisabled(true);
        } else {
          if (+responseCode === 406) {
            toasterState.setToastMsg(
              "Sync job currently in progress",
              "failure"
            );
          } else {
            toasterState.setToastMsg(
              "Failed import contacts. Please try again later.",
              "failure"
            );
          }
        }
      }
    } else {
      saveChanges();
    }
  };

  const handleImportNowAction = async () => {
    if (listChanged) {
      saveChanges();
    } else {
      syncConfirmationPopupState.setShowPopup(true);
      if (!syncConfirmationPopupState.hasUpdate) {
        syncConfirmationPopupState.setCriteria(sourceName);
      }
      syncConfirmationPopupState.setCallback(importCbk);
    }
  };

  const lastFetchedDate = getLastSyncedDate();
  const updatedSyncStartTime = getSyncStartTime();
  const disableSyncNow = syncNow || syncConfirmationPopupState.syncNowDisabled;

  const getResumeMsg = () => {
    if (showResumeMsg) {
      const { date = "", time = "" } =
        DateUtils.getFormattedDateAndTimeInFormat(resumeDate) || {};
      const expectedDateGTM = DateUtils.getTimeZone(resumeDate);
      return (
        <div className="resumeDateText">
          {`Polling has been paused temporarily as the maximum of ${salesforceConnectedData.apiLimitPerDay} requests
      per 24 hour limit reached. It will resume automatically on ${date}
      at ${time} (${expectedDateGTM}).`}
        </div>
      );
    }
  };

  useEffect(() => {
    syncLogsHistoryPopupState.getSyncLog();
  }, [
    syncLogsHistoryPopupState?.limit,
    syncLogsHistoryPopupState?.pageNo,
    syncLogsHistoryPopupState?.source
  ]);

  useEffect(() => {
    syncLogsHistoryPopupState.setCrmId(crmSettingId);
  }, []);

  return (
    <>
      <div className="importNowWrapper">
        <div
          className="importNowCont"
          onMouseEnter={() => (disableSyncNow ? setShowTooltip(true) : {})}
          onMouseLeave={() => (disableSyncNow ? setShowTooltip(false) : {})}
        >
          <Button
            id="importNowBtn"
            name="importNowBtn"
            type="button"
            className="importNowBtn"
            btnText="Sync Now"
            icon={false}
            loader={false}
            iconName=""
            click={handleImportNowAction}
            disabled={disableSyncNow}
          />
          {showTooltip && <CustomTooltip text={"Sync already in progress"} />}
        </div>
        {showResumeMsg
          ? getResumeMsg()
          : lastFetchedDate && (
              <div className="lastSynced">
                <span>Last fetched: </span>
                <span>{lastFetchedDate}</span>
                <span
                  className="viewHistoryBtn"
                  onClick={() => {
                    syncLogsHistoryPopupState.setShowPopup(true);
                    syncLogsHistoryPopupState.getSyncLog();
                  }}
                >
                  View history
                </span>
              </div>
            )}
      </div>
      {disableSyncNow ? (
        <div className="syncInProgress">{`Sync job currently in progress. ${
          updatedSyncStartTime && `Start time: ${updatedSyncStartTime}`
        }`}</div>
      ) : (
        <></>
      )}
    </>
  );
});

export { ImportNow };
export default ImportNow;
