import userDetail from "Stores/userDetail";
import onboardingStoreData from "Stores/onBoardingData";

const InboxUtils = {};

InboxUtils.checkAndAddConnectInbox = (data = []) => {
  userDetail.setConnectedInboxes(data);
  InboxUtils.removeConnectInboxBanner();
};

InboxUtils.removeConnectInboxBanner = () => {
  userDetail.setConnectInboxAccessRevoke(false);
  let hasValue = localStorage.getItem("connectInboxRevokeAccessBanner");
  hasValue && localStorage.removeItem("connectInboxRevokeAccessBanner");
};

InboxUtils.showConnectInboxBanner = () => {
  if (onboardingStoreData?.onBoardingFlags?.isContactsAdded) {
    userDetail.setConnectInboxAccessRevoke(true);
    let hasValue = localStorage.getItem("connectInboxRevokeAccessBanner");
    !hasValue && localStorage.setItem("connectInboxRevokeAccessBanner", true);
  }
};

InboxUtils.getListOfEmailAndName = (list = []) => {
  return list.filter(item => {
    const { email = "", name = null, invalid = "" } = item || {};
    if (
      !["invalid", "donotcontact", "bounced", "opted_out"].includes(
        invalid?.toLowerCase()
      )
    ) {
      return { email, name };
    }
  });
};

InboxUtils.connectInboxAllowed = () => {
  const { connectedInboxes = [], userFeatureFlag = {} } = userDetail || {};
  const {
    multipleInboxEnabled = false,
    noOfInboxesEligible = 1,
    noOfInboxesUsed = 0
  } = userFeatureFlag || {};
  let eligbleCount = noOfInboxesEligible - noOfInboxesUsed;
  return (
    eligbleCount > 0 &&
    (multipleInboxEnabled ||
      (connectedInboxes?.length < 1 && !multipleInboxEnabled))
  );
};

InboxUtils.getEmailAddressList = (item = "") => {
  if (item) {
    if (typeof item === "string") {
      return [{ email: item }];
    } else if (item?.length === 1) {
      return [{ email: item?.[0] }];
    } else if (item?.length > 1) {
      let emailList = [];
      item.map(ele => {
        ele && emailList.push({ email: ele });
      });
      return emailList;
    }
  }
  return [];
};

InboxUtils.getPrimaryInbox = () => {
  const { connectedInboxes = [] } = userDetail || {};
  let tempConnectedInbox = [];
  if (connectedInboxes?.length > 0) {
    tempConnectedInbox = connectedInboxes.filter(account => {
      if (account?.email && account?.status === "ACTIVE") {
        return account;
      }
    });
  }
  return tempConnectedInbox?.[0];
};

InboxUtils.getPrimaryInboxEmail = () => {
  const primaryInbox = InboxUtils.getPrimaryInbox();
  return primaryInbox?.email;
};

InboxUtils.getActiveConnectedInbox = () => {
  const { connectedInboxes = [] } = userDetail || {};
  if (connectedInboxes?.length > 0) {
    const activeConnectedInbox = connectedInboxes.filter(account => {
      const { email = "", status = "" } = account || {};
      return email && status === "ACTIVE";
    });
    return activeConnectedInbox || [];
  }
};

//for single inbox
InboxUtils.getDomainHealthScore = (
  totalChecks = 4,
  dnsRecords = {},
  total = 10
) => {
  let count = 0;
  const recordTypes = ["mxRecord", "spfRecord", "dmarcRecord", "dkim"];
  for (const recordType of recordTypes) {
    if (dnsRecords[recordType]?.length > 0) {
      count++;
    }
  }
  const score = (count / totalChecks) * total;
  return score;
};

InboxUtils.smtpConnectedAccount = (emailId = "") => {
  if (emailId) {
    const { connectedInboxes = [] } = userDetail || {};
    let tempConnectedInbox = [];
    if (connectedInboxes?.length > 0) {
      tempConnectedInbox = connectedInboxes.filter(account => {
        const { email = "", status = "", type = "" } = account || {};
        if (email === emailId && status === "ACTIVE" && type === "smtp") {
          return account;
        }
      });
    }
    return tempConnectedInbox?.length > 0;
  }
  return false;
};

export default InboxUtils;
export { InboxUtils };
