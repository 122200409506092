import React from "react";
import Utils from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import userDetail from "Stores/userDetail";
import {
  showPhonePurchasePopup,
  isPurchaseEnabled
} from "Pages/Dashboard/dataSearch/DataSearchUtils";
import { phoneNumberStore } from "./phoneNumberStore";

const PhoneNumberUtils = {};

const singleContactPurchaseCbk = async (
  contactId,
  type,
  contactInfo,
  setContactInfo,
  cbk
) => {
  const { trialEnabled = false } = userDetail?.userFeatureFlag || {};
  const filteredItem = contactInfo.filter(item => item.id === contactId)[0];
  Utils.mixpanelTrack(MXP_EVENT.FIND_PHONE_NUMBER_CLICKED, {
    pageType: PAGE_TYPE.contact,
    contactName: `${filteredItem?.firstName} ${filteredItem?.lastName}`,
    companyName: filteredItem?.companyName
  });
  if (isPurchaseEnabled(type) || trialEnabled) {
    phoneNumberStore.setPhoneNumberLoading(true);
    const contactData = await phoneNumberStore.purchasePhoneNumber(contactId);
    if (contactData) {
      filteredItem.mobilePhone = contactData?.mobile;
      const mixpanelConstant = {
        pageType: PAGE_TYPE.contact,
        email: filteredItem?.email,
        linkedInUrl: filteredItem?.linkedIn || filteredItem?.linkedinId || "",
        contactName: `${filteredItem?.firstName} ${filteredItem?.lastName}`,
        companyName: filteredItem?.companyName || filteredItem?.company
      };
      if (+contactData?.code === 200) {
        cbk && cbk(false);
        Utils.mixpanelTrack(MXP_EVENT.PHONE_NUMBER_FOUND, mixpanelConstant);
      }
      if (+contactData?.code === 201) {
        filteredItem.hasShowMobilePhone = true;
        Utils.mixpanelTrack(MXP_EVENT.PHONE_NUMBER_MASKED, mixpanelConstant);
      }
      if (+contactData?.code === 404) {
        filteredItem.phoneNumberUnavailable = true;
        Utils.mixpanelTrack(MXP_EVENT.PHONE_NUMBER_NOT_FOUND, mixpanelConstant);
      }
      setContactInfo([...contactInfo]);
    }
  } else {
    showPhonePurchasePopup();
  }
};

PhoneNumberUtils.getPhoneNumber = (
  type,
  id,
  contactInfo,
  setContactInfo,
  cbk
) => {
  return !contactInfo[0].phoneNumberUnavailable ? (
    <div
      className="maskData"
      onClick={() =>
        singleContactPurchaseCbk(id, type, contactInfo, setContactInfo, cbk)
      }
    >
      <span className="maskText">Find phone number</span>
    </div>
  ) : (
    <span className="notFoundText">Phone number unavailable</span>
  );
};

PhoneNumberUtils.getPhoneNumberLoading = () => {
  return (
    <div
      className="linear-background"
      style={{ width: "100%", height: "16px", marginBottom: "2px" }}
    />
  );
};

export { PhoneNumberUtils };
export default PhoneNumberUtils;
