import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { ContactSelectedCount } from "Components/common/ContactSelectedCount";
import { ContactSearchTableContext } from "./ContactSearchTable";
import PageLimit from "Components/common/PageLimit";
import ExportBtn from "Components/common/ExportBtn";
import CustomList from "Pages/Dashboard/dataSearch/CustomList";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
import { BulkPurchaseButton } from "./BulkPurchaseBtn";
import { popupLoaderState } from "../PopupLoader";
import ContactsFound from "./ContactsFound";
import CustomTooltip from "Components/common/CustomTooltip";
import userDetail from "Stores/userDetail";
import {
  verifyBulkActionPerformedAndAllow,
  verifyEmailValidationEnabled
} from "../DataSearchUtils";
import { Utils } from "Utils/utils";
import AddToSequenceButton from "Components/common/AddToSequenceButton";
import { addSequenceInContactPopupState } from "Pages/Dashboard/contacts/addSeqInContactPopup";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";

const SearchTableHeader = observer(() => {
  let history = useHistory();

  const {
    tableLoading = false,
    contactData = {},
    resetAllDynamicState,
    allContactCheckboxAction,
    setTableLoading
  } = useContext(ContactSearchTableContext);

  const {
    exportEnabled = false,
    emailValidationCredits = 0,
    emailValidationEnabled = false
  } = userDetail?.userFeatureFlag || {};

  const { type = "", limit: selectedLimit = "" } =
    dataSearchState?.rangeSelected || {};

  const getContactsByLimit = (limit = 10) => {
    const performPagePerLimit = () => {
      Utils.mixpanelTrack(MXP_EVENT.PAGE_LIMIT_UPDATED, {
        pageType: PAGE_TYPE.discover,
        pageLimit: limit
      });
      setTableLoading(true);
      dataSearchState.setPageLimit(limit);
      dataSearchState.setCurrentPage(1);
      dataSearchState.setIsFromSeoPage(false);
      dataSearchState.searchPageData();
      resetAllDynamicState();
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      verifyBulkActionPerformedAndAllow(performPagePerLimit, () =>
        dataSearchState.setPageLimit(dataSearchState.pageLimit)
      );
    } else {
      performPagePerLimit();
    }
  };

  const updateSelectedDataInStore = data => {
    const tempArr = [];
    data?.contacts?.length > 0 &&
      data.contacts.map(value => {
        if (value?.isSelected) {
          tempArr.push(value.id);
        }
      });
    dataSearchState.setSelectedContactData(tempArr);
  };

  const bulkPurchaseCbk = async (purchaseType = "") => {
    Utils.mixpanelTrack(
      MXP_EVENT[
        purchaseType === "phone"
          ? "VIEW_EMAIL_BULK_CLICKED"
          : "VIEW_EMAIL_PHONE_BULK_CLICKED"
      ],
      {
        pageType: PAGE_TYPE.discover
      }
    );
    popupLoaderState.setData({
      message: "Purchasing your contacts. Please wait...",
      type: "bulkUpload",
      speed: 5
    });
    popupLoaderState.setShowPopup(true);
    await dataSearchState.purchaseBulkContact(purchaseType, history);
    dataSearchState.setSelectedContactData([]);
    if (dataSearchState?.isFromSeoPage) {
      updateSelectedDataInStore(contactData);
    }
  };

  const isContactSelected = () => {
    return dataSearchState?.selectedContactData?.length > 0;
  };

  const getTableHeaderStyle = () => {
    if (isContactSelected()) {
      let style = {};
      style["justifyContent"] = "space-between";
      return style;
    }
  };

  const hasTableData = () => {
    return (!isContactSelected() && !contactData?.totalContacts > 0) ||
      !dataSearchState.isFilterApplied
      ? "noTableSearchData"
      : "";
  };

  const exportAction = () => {
    Utils.mixpanelTrack(MXP_EVENT.EXPORT_CSV_CLICKED, {
      pageType: PAGE_TYPE.discover
    });
    dataSearchState.exportContactsAsCSV();
  };

  const verifyEmailValidationEnabledBeforeAddContactsToSequence = (
    data = {},
    sequenceId = "",
    currentSequence = {}
  ) => {
    if (sequenceId) {
      const tempFlag = {
        sequenceId,
        sequenceEmailValidationEnabled: currentSequence?.emailValidationEnabled,
        emailValidationEnabled,
        emailValidationCredits
      };
      verifyEmailValidationEnabled(
        tempFlag,
        history,
        () =>
          dataSearchState.addContactsToSequence(sequenceId, currentSequence),
        resetAllDynamicState
      );
    }
  };

  const addToSequenceAction = async () => {
    if (
      (await checkTeamSubscriptionActive()) &&
      isContactSelected() &&
      !tableLoading
    ) {
      Utils.mixpanelTrack(MXP_EVENT.ADD_TO_SEQ_CLICKED, {
        pageType: PAGE_TYPE.discover
      });
      addSequenceInContactPopupState.setCallback(
        verifyEmailValidationEnabledBeforeAddContactsToSequence
      );
      addSequenceInContactPopupState.setPageType("discover");
      addSequenceInContactPopupState.setShowPopup(true);
    }
  };

  useEffect(() => {
    addSequenceInContactPopupState.resetAllCheckedContacts &&
      resetAllDynamicState();
  }, [addSequenceInContactPopupState.resetAllCheckedContacts]);

  return (
    <section
      className={`searchTableHeader ${hasTableData()}`}
      style={getTableHeaderStyle()}
    >
      {!isContactSelected() ? (
        <ContactsFound />
      ) : (
        <ContactSelectedCount
          selectedCount={
            type
              ? selectedLimit
              : dataSearchState.selectedContactData?.length || 0
          }
          totalCount={contactData.totalContacts}
          loading={tableLoading}
          allContactSelected={false}
          enableSelectAll={false}
          resetCbk={resetAllDynamicState}
          selectAllCbk={allContactCheckboxAction}
          pageType="discover"
        />
      )}
      {contactData?.totalContacts > 0 && (
        <div className="actions">
          <PageLimit
            limitPerPage={dataSearchState.pageLimit}
            text="Showing"
            cbk={getContactsByLimit}
            totalCount={contactData?.totalContacts}
            customLimit={[10, 25, 50]} // Discover section limited upto 50 contacts per page
          />
          <ExportBtn
            title="Export Contacts"
            selectedEntityCount={
              dataSearchState.selectedContactData?.length || 0
            }
            cbk={exportAction}
            isExportEnabled={exportEnabled}
            checked={isContactSelected()}
            pageType="Discover contact"
          />
          <AddToSequenceButton
            selectedEntityCount={
              dataSearchState.selectedContactData?.length || 0
            }
            cbk={addToSequenceAction}
            loading={tableLoading}
            checked={isContactSelected()}
          />
          <BulkPurchaseButton
            enable={isContactSelected()}
            cbk={bulkPurchaseCbk}
          />
          <CustomList
            hasAddNewList={true}
            hasDelete={false}
            hasEdit={false}
            validateOnHeaderClick={isContactSelected}
            HeaderChildren={<AddToList />}
          />
        </div>
      )}
    </section>
  );
});

const AddToList = () => {
  const [showTooltip, setTooltip] = useState(false);

  return (
    <div
      className={`addToListBtn ${
        dataSearchState.selectedContactData?.length > 0 ? "enabled" : ""
      }`}
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
    >
      <i className="material-icons">playlist_add</i>
      {showTooltip && <CustomTooltip text="Add to List" />}
    </div>
  );
};

export { SearchTableHeader };
export default SearchTableHeader;
